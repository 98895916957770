*, ::before, ::after {
	--tw-translate-x: 0;
	--tw-translate-y: 0;
	--tw-rotate: 0;
	--tw-skew-x: 0;
	--tw-skew-y: 0;
	--tw-scale-x: 1;
	--tw-scale-y: 1;
	--tw-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
	--tw-border-opacity: 1;
	border-color: rgba(229, 231, 235, var(--tw-border-opacity));
	--tw-ring-offset-shadow: 0 0 #0000;
	--tw-ring-shadow: 0 0 #0000;
	--tw-shadow: 0 0 #0000;
	--tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
	--tw-ring-offset-width: 0px;
	--tw-ring-offset-color: #fff;
	--tw-ring-color: rgba(59, 130, 246, 0.5);
	--tw-ring-offset-shadow: 0 0 #0000;
	--tw-ring-shadow: 0 0 #0000;
	--tw-shadow: 0 0 #0000;
}

[type='text'],[type='email'],[type='url'],[type='password'],[type='number'],[type='date'],[type='datetime-local'],[type='month'],[type='search'],[type='tel'],[type='time'],[type='week'],[multiple],textarea,select {
	-webkit-appearance: none;
	   -moz-appearance: none;
	        appearance: none;
	background-color: #fff;
	border-color: #6b7280;
	border-width: 1px;
	border-radius: 0px;
	padding-top: 0.5rem;
	padding-right: 0.75rem;
	padding-bottom: 0.5rem;
	padding-left: 0.75rem;
	font-size: 1rem;
	line-height: 1.5rem;
}

[type='text']:focus, [type='email']:focus, [type='url']:focus, [type='password']:focus, [type='number']:focus, [type='date']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='week']:focus, [multiple]:focus, textarea:focus, select:focus {
	outline: 2px solid transparent;
	outline-offset: 2px;
	--tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
	--tw-ring-offset-width: 0px;
	--tw-ring-offset-color: #fff;
	--tw-ring-color: #2563eb;
	--tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
	--tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
	box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), 0 0 #0000;
	box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
	border-color: #2563eb;
}

input::-moz-placeholder, textarea::-moz-placeholder {
	color: #6b7280;
	opacity: 1;
}

input::placeholder,textarea::placeholder {
	color: #6b7280;
	opacity: 1;
}

::-webkit-datetime-edit-fields-wrapper {
	padding: 0;
}

::-webkit-date-and-time-value {
	min-height: 1.5em;
}

select {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
	background-position: right 0.5rem center;
	background-repeat: no-repeat;
	background-size: 1.5em 1.5em;
	padding-right: 2.5rem;
	-webkit-print-color-adjust: exact;
	        color-adjust: exact;
}

[multiple] {
	background-image: none;
	background-image: initial;
	background-position: 0 0;
	background-position: initial;
	background-repeat: unset;
	background-size: auto auto;
	background-size: initial;
	padding-right: 0.75rem;
	-webkit-print-color-adjust: unset;
	        color-adjust: unset;
}

[type='checkbox'],[type='radio'] {
	-webkit-appearance: none;
	   -moz-appearance: none;
	        appearance: none;
	padding: 0;
	-webkit-print-color-adjust: exact;
	        color-adjust: exact;
	display: inline-block;
	vertical-align: middle;
	background-origin: border-box;
	-webkit-user-select: none;
	   -moz-user-select: none;
	        user-select: none;
	flex-shrink: 0;
	height: 1rem;
	width: 1rem;
	color: #2563eb;
	background-color: #fff;
	border-color: #6b7280;
	border-width: 1px;
}

[type='checkbox'] {
	border-radius: 0px;
}

[type='radio'] {
	border-radius: 100%;
}

[type='checkbox']:focus,[type='radio']:focus {
	outline: 2px solid transparent;
	outline-offset: 2px;
	--tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
	--tw-ring-offset-width: 2px;
	--tw-ring-offset-color: #fff;
	--tw-ring-color: #2563eb;
	--tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
	--tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
	box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), 0 0 #0000;
	box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

[type='checkbox']:checked,[type='radio']:checked {
	border-color: transparent;
	background-color: currentColor;
	background-size: 100% 100%;
	background-position: center;
	background-repeat: no-repeat;
}

[type='checkbox']:checked {
	background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

[type='radio']:checked {
	background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
}

[type='checkbox']:checked:hover,[type='checkbox']:checked:focus,[type='radio']:checked:hover,[type='radio']:checked:focus {
	border-color: transparent;
	background-color: currentColor;
}

[type='checkbox']:indeterminate {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
	border-color: transparent;
	background-color: currentColor;
	background-size: 100% 100%;
	background-position: center;
	background-repeat: no-repeat;
}

[type='checkbox']:indeterminate:hover,[type='checkbox']:indeterminate:focus {
	border-color: transparent;
	background-color: currentColor;
}

[type='file'] {
	background: unset;
	border-color: inherit;
	border-width: 0;
	border-radius: 0;
	padding: 0;
	font-size: unset;
	line-height: inherit;
}

[type='file']:focus {
	outline: 1px auto -webkit-focus-ring-color;
}

.tw-container {
	width: 100%;
}

@media (min-width: 640px) {

	.tw-container {
		max-width: 640px;
	}
}

@media (min-width: 768px) {

	.tw-container {
		max-width: 768px;
	}
}

@media (min-width: 1024px) {

	.tw-container {
		max-width: 1024px;
	}
}

@media (min-width: 1280px) {

	.tw-container {
		max-width: 1280px;
	}
}

@media (min-width: 1536px) {

	.tw-container {
		max-width: 1536px;
	}
}

.tw-prose {
	color: #374151;
	max-width: 65ch;
}

.tw-prose [class~="lead"] {
	color: #4b5563;
	font-size: 1.25em;
	line-height: 1.6;
	margin-top: 1.2em;
	margin-bottom: 1.2em;
}

.tw-prose a {
	color: #111827;
	text-decoration: underline;
	font-weight: 500;
}

.tw-prose strong {
	color: #111827;
	font-weight: 600;
}

.tw-prose ol[type="A"] {
	--list-counter-style: upper-alpha;
}

.tw-prose ol[type="a"] {
	--list-counter-style: lower-alpha;
}

.tw-prose ol[type="A" s] {
	--list-counter-style: upper-alpha;
}

.tw-prose ol[type="a" s] {
	--list-counter-style: lower-alpha;
}

.tw-prose ol[type="I"] {
	--list-counter-style: upper-roman;
}

.tw-prose ol[type="i"] {
	--list-counter-style: lower-roman;
}

.tw-prose ol[type="I" s] {
	--list-counter-style: upper-roman;
}

.tw-prose ol[type="i" s] {
	--list-counter-style: lower-roman;
}

.tw-prose ol[type="1"] {
	--list-counter-style: decimal;
}

.tw-prose ol > li {
	position: relative;
	padding-left: 1.75em;
}

.tw-prose ol > li::before {
	content: counter(list-item, decimal) ".";
	content: counter(list-item, var(--list-counter-style, decimal)) ".";
	position: absolute;
	font-weight: 400;
	color: #6b7280;
	left: 0;
}

.tw-prose ul > li {
	position: relative;
	padding-left: 1.75em;
}

.tw-prose ul > li::before {
	content: "";
	position: absolute;
	background-color: #d1d5db;
	border-radius: 50%;
	width: 0.375em;
	height: 0.375em;
	top: calc(0.875em - 0.1875em);
	left: 0.25em;
}

.tw-prose hr {
	border-color: #e5e7eb;
	border-top-width: 1px;
	margin-top: 3em;
	margin-bottom: 3em;
}

.tw-prose blockquote {
	font-weight: 500;
	font-style: italic;
	color: #111827;
	border-left-width: 0.25rem;
	border-left-color: #e5e7eb;
	quotes: "\201C""\201D""\2018""\2019";
	margin-top: 1.6em;
	margin-bottom: 1.6em;
	padding-left: 1em;
}

.tw-prose blockquote p:first-of-type::before {
	content: open-quote;
}

.tw-prose blockquote p:last-of-type::after {
	content: close-quote;
}

.tw-prose h1 {
	color: #111827;
	font-weight: 800;
	font-size: 2.25em;
	margin-top: 0;
	margin-bottom: 0.8888889em;
	line-height: 1.1111111;
}

.tw-prose h2 {
	color: #111827;
	font-weight: 700;
	font-size: 1.5em;
	margin-top: 2em;
	margin-bottom: 1em;
	line-height: 1.3333333;
}

.tw-prose h3 {
	color: #111827;
	font-weight: 600;
	font-size: 1.25em;
	margin-top: 1.6em;
	margin-bottom: 0.6em;
	line-height: 1.6;
}

.tw-prose h4 {
	color: #111827;
	font-weight: 600;
	margin-top: 1.5em;
	margin-bottom: 0.5em;
	line-height: 1.5;
}

.tw-prose figure figcaption {
	color: #6b7280;
	font-size: 0.875em;
	line-height: 1.4285714;
	margin-top: 0.8571429em;
}

.tw-prose code {
	color: #111827;
	font-weight: 600;
	font-size: 0.875em;
}

.tw-prose code::before {
	content: "`";
}

.tw-prose code::after {
	content: "`";
}

.tw-prose a code {
	color: #111827;
}

.tw-prose pre {
	color: #e5e7eb;
	background-color: #1f2937;
	overflow-x: auto;
	font-size: 0.875em;
	line-height: 1.7142857;
	margin-top: 1.7142857em;
	margin-bottom: 1.7142857em;
	border-radius: 0.375rem;
	padding-top: 0.8571429em;
	padding-right: 1.1428571em;
	padding-bottom: 0.8571429em;
	padding-left: 1.1428571em;
}

.tw-prose pre code {
	background-color: transparent;
	border-width: 0;
	border-radius: 0;
	padding: 0;
	font-weight: 400;
	color: inherit;
	font-size: inherit;
	font-family: inherit;
	line-height: inherit;
}

.tw-prose pre code::before {
	content: none;
}

.tw-prose pre code::after {
	content: none;
}

.tw-prose table {
	width: 100%;
	table-layout: auto;
	text-align: left;
	margin-top: 2em;
	margin-bottom: 2em;
	font-size: 0.875em;
	line-height: 1.7142857;
}

.tw-prose thead {
	color: #111827;
	font-weight: 600;
	border-bottom-width: 1px;
	border-bottom-color: #d1d5db;
}

.tw-prose thead th {
	vertical-align: bottom;
	padding-right: 0.5714286em;
	padding-bottom: 0.5714286em;
	padding-left: 0.5714286em;
}

.tw-prose tbody tr {
	border-bottom-width: 1px;
	border-bottom-color: #e5e7eb;
}

.tw-prose tbody tr:last-child {
	border-bottom-width: 0;
}

.tw-prose tbody td {
	vertical-align: top;
	padding-top: 0.5714286em;
	padding-right: 0.5714286em;
	padding-bottom: 0.5714286em;
	padding-left: 0.5714286em;
}

.tw-prose {
	font-size: 1rem;
	line-height: 1.75;
}

.tw-prose p {
	margin-top: 1.25em;
	margin-bottom: 1.25em;
}

.tw-prose img {
	margin-top: 2em;
	margin-bottom: 2em;
}

.tw-prose video {
	margin-top: 2em;
	margin-bottom: 2em;
}

.tw-prose figure {
	margin-top: 2em;
	margin-bottom: 2em;
}

.tw-prose figure > * {
	margin-top: 0;
	margin-bottom: 0;
}

.tw-prose h2 code {
	font-size: 0.875em;
}

.tw-prose h3 code {
	font-size: 0.9em;
}

.tw-prose ol {
	margin-top: 1.25em;
	margin-bottom: 1.25em;
}

.tw-prose ul {
	margin-top: 1.25em;
	margin-bottom: 1.25em;
}

.tw-prose li {
	margin-top: 0.5em;
	margin-bottom: 0.5em;
}

.tw-prose > ul > li p {
	margin-top: 0.75em;
	margin-bottom: 0.75em;
}

.tw-prose > ul > li > *:first-child {
	margin-top: 1.25em;
}

.tw-prose > ul > li > *:last-child {
	margin-bottom: 1.25em;
}

.tw-prose > ol > li > *:first-child {
	margin-top: 1.25em;
}

.tw-prose > ol > li > *:last-child {
	margin-bottom: 1.25em;
}

.tw-prose ul ul, .tw-prose ul ol, .tw-prose ol ul, .tw-prose ol ol {
	margin-top: 0.75em;
	margin-bottom: 0.75em;
	margin-top: 0.75em;
	margin-bottom: 0.75em;
	margin-top: 0.75em;
	margin-bottom: 0.75em;
	margin-top: 0.75em;
	margin-bottom: 0.75em;
}

.tw-prose hr + * {
	margin-top: 0;
}

.tw-prose h2 + * {
	margin-top: 0;
}

.tw-prose h3 + * {
	margin-top: 0;
}

.tw-prose h4 + * {
	margin-top: 0;
}

.tw-prose thead th:first-child {
	padding-left: 0;
}

.tw-prose thead th:last-child {
	padding-right: 0;
}

.tw-prose tbody td:first-child {
	padding-left: 0;
}

.tw-prose tbody td:last-child {
	padding-right: 0;
}

.tw-prose > :first-child {
	margin-top: 0;
}

.tw-prose > :last-child {
	margin-bottom: 0;
}

.tw-sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border-width: 0;
}

.tw-pointer-events-none {
	pointer-events: none;
}

.tw-pointer-events-auto {
	pointer-events: auto;
}

.tw-invisible {
	visibility: hidden;
}

.tw-fixed {
	position: fixed;
}

.tw-absolute {
	position: absolute;
}

.tw-relative {
	position: relative;
}

.tw-sticky {
	position: -webkit-sticky;
	position: sticky;
}

.tw-inset-0 {
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
}

.tw-inset-y-0 {
	top: 0px;
	bottom: 0px;
}

.tw-inset-x-0\.5 {
	left: 0.125rem;
	right: 0.125rem;
}

.tw-inset-x-0 {
	left: 0px;
	right: 0px;
}

.tw-right-0 {
	right: 0px;
}

.tw-top-0 {
	top: 0px;
}

.tw-left-full {
	left: 100%;
}

.tw-left-0 {
	left: 0px;
}

.tw-top-10 {
	top: 2.5rem;
}

.tw-top-12 {
	top: 3rem;
}

.tw-bottom-0 {
	bottom: 0px;
}

.tw-top-9 {
	top: 2.25rem;
}

.tw--top-4 {
	top: -1rem;
}

.tw-top-6 {
	top: 1.5rem;
}

.tw-top-2\.5 {
	top: 0.625rem;
}

.tw-right-2\.5 {
	right: 0.625rem;
}

.tw-top-2 {
	top: 0.5rem;
}

.tw-right-2 {
	right: 0.5rem;
}

.tw--top-3 {
	top: -0.75rem;
}

.tw--bottom-2 {
	bottom: -0.5rem;
}

.tw-bottom-\[2em\] {
	bottom: 2em;
}

.tw-top-1 {
	top: 0.25rem;
}

.tw-left-3 {
	left: 0.75rem;
}

.tw-z-0 {
	z-index: 0;
}

.tw-z-10 {
	z-index: 10;
}

.tw-z-50 {
	z-index: 50;
}

.tw-z-30 {
	z-index: 30;
}

.tw-z-20 {
	z-index: 20;
}

.tw-z-40 {
	z-index: 40;
}

.tw-col-span-12 {
	grid-column: span 12 / span 12;
}

.tw-col-span-6 {
	grid-column: span 6 / span 6;
}

.tw-col-span-8 {
	grid-column: span 8 / span 8;
}

.tw-col-span-1 {
	grid-column: span 1 / span 1;
}

.tw-col-span-5 {
	grid-column: span 5 / span 5;
}

.tw-col-span-7 {
	grid-column: span 7 / span 7;
}

.tw-col-span-2 {
	grid-column: span 2 / span 2;
}

.tw-col-span-10 {
	grid-column: span 10 / span 10;
}

.tw-col-span-3 {
	grid-column: span 3 / span 3;
}

.tw-float-right {
	float: right;
}

.tw-m-4 {
	margin: 1rem;
}

.tw--m-3 {
	margin: -0.75rem;
}

.tw-m-1 {
	margin: 0.25rem;
}

.tw--m-1 {
	margin: -0.25rem;
}

.tw--m-2 {
	margin: -0.5rem;
}

.tw-m-0 {
	margin: 0px;
}

.tw-m-2 {
	margin: 0.5rem;
}

.tw-mx-2 {
	margin-left: 0.5rem;
	margin-right: 0.5rem;
}

.tw-mx-1 {
	margin-left: 0.25rem;
	margin-right: 0.25rem;
}

.tw-mx-auto {
	margin-left: auto;
	margin-right: auto;
}

.tw-my-0 {
	margin-top: 0px;
	margin-bottom: 0px;
}

.tw--my-2 {
	margin-top: -0.5rem;
	margin-bottom: -0.5rem;
}

.tw-my-4 {
	margin-top: 1rem;
	margin-bottom: 1rem;
}

.tw-my-6 {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
}

.tw-my-3 {
	margin-top: 0.75rem;
	margin-bottom: 0.75rem;
}

.tw-my-2 {
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
}

.tw-my-0\.5 {
	margin-top: 0.125rem;
	margin-bottom: 0.125rem;
}

.tw-my-1 {
	margin-top: 0.25rem;
	margin-bottom: 0.25rem;
}

.tw-mx-0 {
	margin-left: 0px;
	margin-right: 0px;
}

.tw-my-5 {
	margin-top: 1.25rem;
	margin-bottom: 1.25rem;
}

.tw-mx-4 {
	margin-left: 1rem;
	margin-right: 1rem;
}

.tw-mr-1 {
	margin-right: 0.25rem;
}

.tw-ml-2 {
	margin-left: 0.5rem;
}

.tw--ml-px {
	margin-left: -1px;
}

.tw-mt-0 {
	margin-top: 0px;
}

.tw-mt-12 {
	margin-top: 3rem;
}

.tw-ml-3 {
	margin-left: 0.75rem;
}

.tw-mr-2 {
	margin-right: 0.5rem;
}

.tw-ml-1 {
	margin-left: 0.25rem;
}

.tw-mt-8 {
	margin-top: 2rem;
}

.tw-mt-2 {
	margin-top: 0.5rem;
}

.tw-mt-6 {
	margin-top: 1.5rem;
}

.tw-mt-5 {
	margin-top: 1.25rem;
}

.tw-mt-1 {
	margin-top: 0.25rem;
}

.tw-mb-4 {
	margin-bottom: 1rem;
}

.tw-ml-10 {
	margin-left: 2.5rem;
}

.tw-ml-4 {
	margin-left: 1rem;
}

.tw-mt-3 {
	margin-top: 0.75rem;
}

.tw-mb-0 {
	margin-bottom: 0px;
}

.tw-mr-0\.5 {
	margin-right: 0.125rem;
}

.tw-mr-0 {
	margin-right: 0px;
}

.tw-ml-14 {
	margin-left: 3.5rem;
}

.tw-mb-5 {
	margin-bottom: 1.25rem;
}

.tw-mr-4 {
	margin-right: 1rem;
}

.tw-mt-4 {
	margin-top: 1rem;
}

.tw--mt-2 {
	margin-top: -0.5rem;
}

.tw--mt-px {
	margin-top: -1px;
}

.tw--mr-px {
	margin-right: -1px;
}

.tw--mt-2 {
	margin-top: -0.5rem;
}

.tw--ml-2 {
	margin-left: -0.5rem;
}

.tw-mb-2 {
	margin-bottom: 0.5rem;
}

.tw-mb-3 {
	margin-bottom: 0.75rem;
}

.tw-mb-16 {
	margin-bottom: 4rem;
}

.tw-mt-10 {
	margin-top: 2.5rem;
}

.tw-mb-1 {
	margin-bottom: 0.25rem;
}

.tw-mb-8 {
	margin-bottom: 2rem;
}

.tw-mb-6 {
	margin-bottom: 1.5rem;
}

.tw-mr-2\.5 {
	margin-right: 0.625rem;
}

.tw-mr-3 {
	margin-right: 0.75rem;
}

.tw-mb-0\.5 {
	margin-bottom: 0.125rem;
}

.tw-mt-0\.5 {
	margin-top: 0.125rem;
}

.tw-mb-32 {
	margin-bottom: 8rem;
}

.tw-mb-12 {
	margin-bottom: 3rem;
}

.tw--mt-1 {
	margin-top: -0.25rem;
}

.tw--ml-1 {
	margin-left: -0.25rem;
}

.tw-ml-\[-2\] {
	margin-left: -2;
}

.tw-mb-10 {
	margin-bottom: 2.5rem;
}

.tw--mb-2 {
	margin-bottom: -0.5rem;
}

.tw--mb-px {
	margin-bottom: -1px;
}

.tw--mt-4 {
	margin-top: -1rem;
	margin-top: -1rem;
}

.tw--mt-3 {
	margin-top: -0.75rem;
}

.tw-ml-8 {
	margin-left: 2rem;
}

.tw-ml-6 {
	margin-left: 1.5rem;
}

.tw-ml-0\.5 {
	margin-left: 0.125rem;
}

.tw-ml-0 {
	margin-left: 0px;
}

.tw-ml-5 {
	margin-left: 1.25rem;
}

.tw-mr-5 {
	margin-right: 1.25rem;
}

.tw-mt-1\.5 {
	margin-top: 0.375rem;
}

.tw--mt-0\.5 {
	margin-top: -0.125rem;
}

.tw--mt-0 {
	margin-top: 0px;
}

.tw-mr-6 {
	margin-right: 1.5rem;
}

.tw-mr-8 {
	margin-right: 2rem;
}

.tw-mb-1\.5 {
	margin-bottom: 0.375rem;
}

.tw-ml-3\.5 {
	margin-left: 0.875rem;
}

.tw-mt-28 {
	margin-top: 7rem;
}

.tw-block {
	display: block;
}

.\!tw-block {
	display: block !important;
}

.tw-inline-block {
	display: inline-block;
}

.tw-inline {
	display: inline;
}

.tw-flex {
	display: flex;
}

.tw-inline-flex {
	display: inline-flex;
}

.tw-table {
	display: table;
}

.tw-flow-root {
	display: flow-root;
}

.tw-grid {
	display: grid;
}

.tw-contents {
	display: contents;
}

.tw-hidden {
	display: none;
}

.tw-h-4 {
	height: 1rem;
}

.tw-h-3 {
	height: 0.75rem;
}

.tw-h-8 {
	height: 2rem;
}

.tw-h-16 {
	height: 4rem;
}

.tw-h-10 {
	height: 2.5rem;
}

.tw-h-5 {
	height: 1.25rem;
}

.tw-h-6 {
	height: 1.5rem;
}

.tw-h-12 {
	height: 3rem;
}

.tw-h-2\.5 {
	height: 0.625rem;
}

.tw-h-2 {
	height: 0.5rem;
}

.tw-h-full {
	height: 100%;
}

.tw-h-1\.5 {
	height: 0.375rem;
}

.tw-h-1 {
	height: 0.25rem;
}

.tw-h-\[34px\] {
	height: 34px;
}

.tw-h-\[30em\] {
	height: 30em;
}

.tw-h-24 {
	height: 6rem;
}

.tw-h-3\.5 {
	height: 0.875rem;
}

.tw-h-20 {
	height: 5rem;
}

.tw-h-28 {
	height: 7rem;
}

.tw-h-9 {
	height: 2.25rem;
}

.tw-h-0\.5 {
	height: 0.125rem;
}

.tw-h-0 {
	height: 0px;
}

.tw-h-screen {
	height: 100vh;
}

.tw-h-\[78px\] {
	height: 78px;
}

.tw-h-3\/4 {
	height: calc(100vh - 275px);
}

.tw-h-72 {
	height: 18rem;
}

.tw-h-auto {
	height: auto;
}

.tw-max-h-nav {
	max-height: calc(100vh - 75px);
}

.tw-max-h-3\/4 {
	max-height: calc(100vh - 275px);
}

.tw-min-h-screen {
	min-height: 100vh;
}

.tw-min-h-12 {
	min-height: 3rem;
}

.tw-min-h-0 {
	min-height: 0px;
}

.tw-min-h-\[200px\] {
	min-height: 200px;
}

.tw-min-h-full {
	min-height: 100%;
}

.tw-w-4 {
	width: 1rem;
}

.tw-w-3 {
	width: 0.75rem;
}

.tw-w-8 {
	width: 2rem;
}

.tw-w-16 {
	width: 4rem;
}

.tw-w-full {
	width: 100%;
}

.tw-w-5 {
	width: 1.25rem;
}

.tw-w-56 {
	width: 14rem;
}

.tw-w-6 {
	width: 1.5rem;
}

.tw-w-72 {
	width: 18rem;
}

.tw-w-auto {
	width: auto;
}

.tw-w-2\.5 {
	width: 0.625rem;
}

.tw-w-2 {
	width: 0.5rem;
}

.tw-w-96 {
	width: 24rem;
}

.tw-w-32 {
	width: 8rem;
}

.tw-w-48 {
	width: 12rem;
}

.tw-w-1\.5 {
	width: 0.375rem;
}

.tw-w-1 {
	width: 0.25rem;
}

.tw-w-10 {
	width: 2.5rem;
}

.tw-w-screen {
	width: 100vw;
}

.tw-w-60 {
	width: 15rem;
}

.tw-w-px {
	width: 1px;
}

.tw-w-max {
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
}

.tw-w-24 {
	width: 6rem;
}

.tw-w-1\/4 {
	width: 25%;
}

.tw-w-1\/5 {
	width: 20%;
}

.tw-w-14 {
	width: 3.5rem;
}

.tw-w-0 {
	width: 0px;
}

.tw-w-20 {
	width: 5rem;
}

.tw-w-12 {
	width: 3rem;
}

.tw-w-1\/3 {
	width: 33.333333%;
}

.tw-w-2\/3 {
	width: 66.666667%;
}

.tw-w-1\/2 {
	width: 50%;
}

.tw-w-52 {
	width: 13rem;
}

.tw-w-36 {
	width: 9rem;
}

.tw-w-9 {
	width: 2.25rem;
}

.tw-w-40 {
	width: 10rem;
}

.tw-w-1\/12 {
	width: 8.333333%;
}

.tw-w-5\/12 {
	width: 41.666667%;
}

.tw-w-80 {
	width: 20rem;
}

.tw-w-44 {
	width: 11rem;
}

.tw-w-28 {
	width: 7rem;
}

.tw-w-3\/4 {
	width: 75%;
}

.tw-w-64 {
	width: 16rem;
}

.tw-w-11 {
	width: 2.75rem;
}

.\!tw-w-full {
	width: 100% !important;
}

.tw-w-120 {
	width: 30rem;
}

.tw-min-w-max {
	min-width: -webkit-max-content;
	min-width: -moz-max-content;
	min-width: max-content;
}

.tw-min-w-full {
	min-width: 100%;
}

.tw-min-w-min {
	min-width: -webkit-min-content;
	min-width: -moz-min-content;
	min-width: min-content;
}

.tw-min-w-0 {
	min-width: 0px;
}

.tw-max-w-2xl {
	max-width: 42rem;
}

.tw-max-w-max {
	max-width: -webkit-max-content;
	max-width: -moz-max-content;
	max-width: max-content;
}

.tw-max-w-sm {
	max-width: 24rem;
}

.tw-max-w-7xl {
	max-width: 80rem;
}

.tw-max-w-xs {
	max-width: 20rem;
}

.tw-max-w-md {
	max-width: 28rem;
}

.tw-max-w-lg {
	max-width: 32rem;
}

.tw-max-w-3xl {
	max-width: 48rem;
}

.tw-max-w-4xl {
	max-width: 56rem;
}

.tw-max-w-full {
	max-width: 100%;
}

.tw-max-w-5xl {
	max-width: 64rem;
}

.tw-max-w-xl {
	max-width: 36rem;
}

.tw-flex-1 {
	flex: 1 1;
}

.tw-flex-none {
	flex: none;
}

.tw-flex-auto {
	flex: 1 1 auto;
}

.tw-flex-shrink-0 {
	flex-shrink: 0;
}

.tw-flex-grow {
	flex-grow: 1;
}

.tw-table-auto {
	table-layout: auto;
}

.tw-table-fixed {
	table-layout: fixed;
}

.tw-origin-top-right {
	transform-origin: top right;
}

.tw-origin-top-left {
	transform-origin: top left;
}

.tw--translate-x-full {
	--tw-translate-x: -100%;
	transform: var(--tw-transform);
}

.tw-translate-y-56 {
	--tw-translate-y: 14rem;
	transform: var(--tw-transform);
}

.tw--translate-y-full {
	--tw-translate-y: -100%;
	transform: var(--tw-transform);
}

.tw--translate-y-1\/2 {
	--tw-translate-y: -50%;
	transform: var(--tw-transform);
}

.tw-translate-x-1\/2 {
	--tw-translate-x: 50%;
	transform: var(--tw-transform);
}

.tw-translate-x-5 {
	--tw-translate-x: 1.25rem;
	transform: var(--tw-transform);
}

.tw-translate-x-0 {
	--tw-translate-x: 0px;
	transform: var(--tw-transform);
}

.tw-translate-x-full {
	--tw-translate-x: 100%;
	transform: var(--tw-transform);
}

.tw-skew-x-1 {
	--tw-skew-x: 1deg;
	transform: var(--tw-transform);
}

.tw-skew-x-0 {
	--tw-skew-x: 0deg;
	transform: var(--tw-transform);
}

.tw-scale-95 {
	--tw-scale-x: .95;
	--tw-scale-y: .95;
	transform: var(--tw-transform);
}

.tw-scale-100 {
	--tw-scale-x: 1;
	--tw-scale-y: 1;
	transform: var(--tw-transform);
}

.tw-transform {
	transform: var(--tw-transform);
}

@-webkit-keyframes tw-spin {

	to {
		transform: rotate(360deg);
	}
}

@keyframes tw-spin {

	to {
		transform: rotate(360deg);
	}
}

.tw-animate-spin {
	-webkit-animation: tw-spin 1s linear infinite;
	        animation: tw-spin 1s linear infinite;
}

@-webkit-keyframes tw-pulse {

	50% {
		opacity: .5;
	}
}

@keyframes tw-pulse {

	50% {
		opacity: .5;
	}
}

.tw-animate-pulse {
	-webkit-animation: tw-pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
	        animation: tw-pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.tw-cursor-pointer {
	cursor: pointer;
}

.tw-cursor-not-allowed {
	cursor: not-allowed;
}

.tw-cursor-help {
	cursor: help;
}

.tw-cursor-move {
	cursor: move;
}

.tw-cursor-default {
	cursor: default;
}

.tw-select-all {
	-webkit-user-select: all;
	   -moz-user-select: all;
	        user-select: all;
}

.\!tw-resize-none {
	resize: none !important;
}

.tw-list-outside {
	list-style-position: outside;
}

.tw-list-disc {
	list-style-type: disc;
}

.tw-list-none {
	list-style-type: none;
}

.tw-appearance-none {
	-webkit-appearance: none;
	   -moz-appearance: none;
	        appearance: none;
}

.tw-grid-cols-12 {
	grid-template-columns: repeat(12, minmax(0, 1fr));
}

.tw-grid-cols-6 {
	grid-template-columns: repeat(6, minmax(0, 1fr));
}

.tw-grid-cols-1 {
	grid-template-columns: repeat(1, minmax(0, 1fr));
}

.tw-grid-cols-2 {
	grid-template-columns: repeat(2, minmax(0, 1fr));
}

.tw-grid-cols-3 {
	grid-template-columns: repeat(3, minmax(0, 1fr));
}

.tw-grid-cols-8 {
	grid-template-columns: repeat(8, minmax(0, 1fr));
}

.tw-flex-row {
	flex-direction: row;
}

.tw-flex-row-reverse {
	flex-direction: row-reverse;
}

.tw-flex-col {
	flex-direction: column;
}

.tw-flex-wrap {
	flex-wrap: wrap;
}

.tw-items-start {
	align-items: flex-start;
}

.tw-items-end {
	align-items: flex-end;
}

.tw-items-center {
	align-items: center;
}

.tw-items-baseline {
	align-items: baseline;
}

.tw-items-stretch {
	align-items: stretch;
}

.tw-justify-start {
	justify-content: flex-start;
}

.tw-justify-end {
	justify-content: flex-end;
}

.tw-justify-center {
	justify-content: center;
}

.tw-justify-between {
	justify-content: space-between;
}

.tw-justify-around {
	justify-content: space-around;
}

.tw-justify-items-center {
	justify-items: center;
}

.tw-gap-2 {
	grid-gap: 0.5rem;
	gap: 0.5rem;
}

.tw-gap-1 {
	grid-gap: 0.25rem;
	gap: 0.25rem;
}

.tw-gap-6 {
	grid-gap: 1.5rem;
	gap: 1.5rem;
}

.tw-gap-4 {
	grid-gap: 1rem;
	gap: 1rem;
}

.tw-gap-10 {
	grid-gap: 2.5rem;
	gap: 2.5rem;
}

.tw-gap-3 {
	grid-gap: 0.75rem;
	gap: 0.75rem;
}

.tw-gap-8 {
	grid-gap: 2rem;
	gap: 2rem;
}

.tw-gap-5 {
	grid-gap: 1.25rem;
	gap: 1.25rem;
}

.tw-gap-0\.5 {
	grid-gap: 0.125rem;
	gap: 0.125rem;
}

.tw-gap-0 {
	grid-gap: 0px;
	gap: 0px;
}

.tw-gap-y-2 {
	grid-row-gap: 0.5rem;
	row-gap: 0.5rem;
}

.tw-gap-x-1 {
	grid-column-gap: 0.25rem;
	-moz-column-gap: 0.25rem;
	     column-gap: 0.25rem;
}

.tw-gap-x-2 {
	grid-column-gap: 0.5rem;
	-moz-column-gap: 0.5rem;
	     column-gap: 0.5rem;
}

.tw-gap-x-3 {
	grid-column-gap: 0.75rem;
	-moz-column-gap: 0.75rem;
	     column-gap: 0.75rem;
}

.tw-gap-x-4 {
	grid-column-gap: 1rem;
	-moz-column-gap: 1rem;
	     column-gap: 1rem;
}

.tw-gap-y-5 {
	grid-row-gap: 1.25rem;
	row-gap: 1.25rem;
}

.tw-gap-y-4 {
	grid-row-gap: 1rem;
	row-gap: 1rem;
}

.tw-gap-x-8 {
	grid-column-gap: 2rem;
	-moz-column-gap: 2rem;
	     column-gap: 2rem;
}

.tw-gap-y-1 {
	grid-row-gap: 0.25rem;
	row-gap: 0.25rem;
}

.tw-gap-x-1\.5 {
	grid-column-gap: 0.375rem;
	-moz-column-gap: 0.375rem;
	     column-gap: 0.375rem;
}

.tw-gap-y-6 {
	grid-row-gap: 1.5rem;
	row-gap: 1.5rem;
}

.tw-gap-y-10 {
	grid-row-gap: 2.5rem;
	row-gap: 2.5rem;
}

.tw-gap-x-10 {
	grid-column-gap: 2.5rem;
	-moz-column-gap: 2.5rem;
	     column-gap: 2.5rem;
}

.tw-space-x-2 > :not([hidden]) ~ :not([hidden]) {
	--tw-space-x-reverse: 0;
	margin-right: calc(0.5rem * var(--tw-space-x-reverse));
	margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.tw-space-x-1 > :not([hidden]) ~ :not([hidden]) {
	--tw-space-x-reverse: 0;
	margin-right: calc(0.25rem * var(--tw-space-x-reverse));
	margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.tw-space-y-4 > :not([hidden]) ~ :not([hidden]) {
	--tw-space-y-reverse: 0;
	margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
	margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.tw-space-x-8 > :not([hidden]) ~ :not([hidden]) {
	--tw-space-x-reverse: 0;
	margin-right: calc(2rem * var(--tw-space-x-reverse));
	margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
}

.tw-space-x-5 > :not([hidden]) ~ :not([hidden]) {
	--tw-space-x-reverse: 0;
	margin-right: calc(1.25rem * var(--tw-space-x-reverse));
	margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.tw-space-y-6 > :not([hidden]) ~ :not([hidden]) {
	--tw-space-y-reverse: 0;
	margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
	margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.tw-space-y-8 > :not([hidden]) ~ :not([hidden]) {
	--tw-space-y-reverse: 0;
	margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
	margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}

.tw-space-x-3 > :not([hidden]) ~ :not([hidden]) {
	--tw-space-x-reverse: 0;
	margin-right: calc(0.75rem * var(--tw-space-x-reverse));
	margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.tw-space-y-3 > :not([hidden]) ~ :not([hidden]) {
	--tw-space-y-reverse: 0;
	margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
	margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
}

.tw-space-y-1 > :not([hidden]) ~ :not([hidden]) {
	--tw-space-y-reverse: 0;
	margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
	margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
}

.tw-space-y-2 > :not([hidden]) ~ :not([hidden]) {
	--tw-space-y-reverse: 0;
	margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
	margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
}

.tw-space-x-4 > :not([hidden]) ~ :not([hidden]) {
	--tw-space-x-reverse: 0;
	margin-right: calc(1rem * var(--tw-space-x-reverse));
	margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.tw-divide-y > :not([hidden]) ~ :not([hidden]) {
	--tw-divide-y-reverse: 0;
	border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
	border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.tw-divide-x-0 > :not([hidden]) ~ :not([hidden]) {
	--tw-divide-x-reverse: 0;
	border-right-width: calc(0px * var(--tw-divide-x-reverse));
	border-left-width: calc(0px * calc(1 - var(--tw-divide-x-reverse)));
}

.tw-divide-x > :not([hidden]) ~ :not([hidden]) {
	--tw-divide-x-reverse: 0;
	border-right-width: calc(1px * var(--tw-divide-x-reverse));
	border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
}

.tw-divide-y-2 > :not([hidden]) ~ :not([hidden]) {
	--tw-divide-y-reverse: 0;
	border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse)));
	border-bottom-width: calc(2px * var(--tw-divide-y-reverse));
}

.tw-divide-solid > :not([hidden]) ~ :not([hidden]) {
	border-style: solid;
}

.tw-divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
	--tw-divide-opacity: 1;
	border-color: rgba(229, 231, 235, var(--tw-divide-opacity));
}

.tw-divide-gray-300 > :not([hidden]) ~ :not([hidden]) {
	--tw-divide-opacity: 1;
	border-color: rgba(209, 213, 219, var(--tw-divide-opacity));
}

.tw-place-self-start {
	place-self: start;
}

.tw-place-self-center {
	place-self: center;
}

.tw-self-start {
	align-self: flex-start;
}

.tw-self-center {
	align-self: center;
}

.tw-overflow-hidden {
	overflow: hidden;
}

.tw-overflow-visible {
	overflow: visible;
}

.tw-overflow-x-auto {
	overflow-x: auto;
}

.tw-overflow-y-auto {
	overflow-y: auto;
}

.tw-truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.tw-whitespace-normal {
	white-space: normal;
}

.tw-whitespace-nowrap {
	white-space: nowrap;
}

.tw-rounded-full {
	border-radius: 9999px;
}

.tw-rounded-md {
	border-radius: 0.375rem;
}

.tw-rounded {
	border-radius: 0.25rem;
}

.tw-rounded-lg {
	border-radius: 0.5rem;
}

.tw-rounded-sm {
	border-radius: 0.125rem;
}

.tw-rounded-none {
	border-radius: 0px;
}

.tw-rounded-xl {
	border-radius: 0.75rem;
}

.tw-rounded-3xl {
	border-radius: 1.5rem;
}

.\!tw-rounded-lg {
	border-radius: 0.5rem !important;
}

.tw-rounded-l-md {
	border-top-left-radius: 0.375rem;
	border-bottom-left-radius: 0.375rem;
}

.tw-rounded-r-md {
	border-top-right-radius: 0.375rem;
	border-bottom-right-radius: 0.375rem;
}

.tw-rounded-t-md {
	border-top-left-radius: 0.375rem;
	border-top-right-radius: 0.375rem;
}

.tw-rounded-b-md {
	border-bottom-right-radius: 0.375rem;
	border-bottom-left-radius: 0.375rem;
}

.tw-rounded-r-none {
	border-top-right-radius: 0px;
	border-bottom-right-radius: 0px;
}

.tw-rounded-br-md {
	border-bottom-right-radius: 0.375rem;
}

.tw-rounded-bl-lg {
	border-bottom-left-radius: 0.5rem;
}

.tw-rounded-tl-md {
	border-top-left-radius: 0.375rem;
}

.tw-rounded-tr-md {
	border-top-right-radius: 0.375rem;
}

.tw-border {
	border-width: 1px;
}

.tw-border-2 {
	border-width: 2px;
}

.tw-border-0 {
	border-width: 0px;
}

.tw-border-4 {
	border-width: 4px;
}

.\!tw-border {
	border-width: 1px !important;
}

.tw-border-b {
	border-bottom-width: 1px;
}

.tw-border-l-4 {
	border-left-width: 4px;
}

.tw-border-l {
	border-left-width: 1px;
}

.tw-border-t {
	border-top-width: 1px;
}

.tw-border-l-0 {
	border-left-width: 0px;
}

.tw-border-r-0 {
	border-right-width: 0px;
}

.tw-border-t-0 {
	border-top-width: 0px;
}

.tw-border-r {
	border-right-width: 1px;
}

.tw-border-b-0 {
	border-bottom-width: 0px;
}

.tw-border-t-2 {
	border-top-width: 2px;
}

.tw-border-l-2 {
	border-left-width: 2px;
}

.tw-border-b-2 {
	border-bottom-width: 2px;
}

.tw-border-solid {
	border-style: solid;
}

.\!tw-border-solid {
	border-style: solid !important;
}

.tw-border-dashed {
	border-style: dashed;
}

.tw-border-none {
	border-style: none;
}

.tw-border-transparent {
	border-color: transparent;
}

.tw-border-gray-200 {
	--tw-border-opacity: 1;
	border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}

.tw-border-red-400 {
	--tw-border-opacity: 1;
	border-color: rgba(248, 113, 113, var(--tw-border-opacity));
}

.tw-border-gray-300 {
	--tw-border-opacity: 1;
	border-color: rgba(209, 213, 219, var(--tw-border-opacity));
}

.tw-border-gray-700 {
	--tw-border-opacity: 1;
	border-color: rgba(55, 65, 81, var(--tw-border-opacity));
}

.tw-border-teal-200 {
	--tw-border-opacity: 1;
	border-color: rgba(175, 236, 239, var(--tw-border-opacity));
}

.tw-border-gray-100 {
	--tw-border-opacity: 1;
	border-color: rgba(243, 244, 246, var(--tw-border-opacity));
}

.tw-border-gray-50 {
	--tw-border-opacity: 1;
	border-color: rgba(249, 250, 251, var(--tw-border-opacity));
}

.tw-border-black {
	--tw-border-opacity: 1;
	border-color: rgba(0, 0, 0, var(--tw-border-opacity));
}

.tw-border-white {
	--tw-border-opacity: 1;
	border-color: rgba(255, 255, 255, var(--tw-border-opacity));
}

.tw-border-teal-100 {
	--tw-border-opacity: 1;
	border-color: rgba(213, 245, 246, var(--tw-border-opacity));
}

.tw-border-yellow-300 {
	--tw-border-opacity: 1;
	border-color: rgba(252, 211, 77, var(--tw-border-opacity));
}

.tw-border-teal-600 {
	--tw-border-opacity: 1;
	border-color: rgba(4, 116, 129, var(--tw-border-opacity));
}

.tw-border-red-200 {
	--tw-border-opacity: 1;
	border-color: rgba(254, 202, 202, var(--tw-border-opacity));
}

.tw-border-red-700 {
	--tw-border-opacity: 1;
	border-color: rgba(185, 28, 28, var(--tw-border-opacity));
}

.tw-border-teal-50 {
	--tw-border-opacity: 1;
	border-color: rgba(237, 250, 250, var(--tw-border-opacity));
}

.tw-border-gray-500 {
	--tw-border-opacity: 1;
	border-color: rgba(107, 114, 128, var(--tw-border-opacity));
}

.tw-border-zinc-300 {
	--tw-border-opacity: 1;
	border-color: rgba(212, 212, 216, var(--tw-border-opacity));
}

.tw-border-red-300 {
	--tw-border-opacity: 1;
	border-color: rgba(252, 165, 165, var(--tw-border-opacity));
}

.tw-border-red-500 {
	--tw-border-opacity: 1;
	border-color: rgba(239, 68, 68, var(--tw-border-opacity));
}

.tw-border-zinc-400 {
	--tw-border-opacity: 1;
	border-color: rgba(161, 161, 170, var(--tw-border-opacity));
}

.tw-border-zinc-200 {
	--tw-border-opacity: 1;
	border-color: rgba(228, 228, 231, var(--tw-border-opacity));
}

.tw-border-teal-500 {
	--tw-border-opacity: 1;
	border-color: rgba(6, 148, 162, var(--tw-border-opacity));
}

.tw-border-teal-300 {
	--tw-border-opacity: 1;
	border-color: rgba(126, 220, 226, var(--tw-border-opacity));
}

.tw-border-teal-700 {
	--tw-border-opacity: 1;
	border-color: rgba(3, 102, 114, var(--tw-border-opacity));
}

.tw-border-red-100 {
	--tw-border-opacity: 1;
	border-color: rgba(254, 226, 226, var(--tw-border-opacity));
}

.tw-border-gray-400 {
	--tw-border-opacity: 1;
	border-color: rgba(156, 163, 175, var(--tw-border-opacity));
}

.tw-border-yellow-100 {
	--tw-border-opacity: 1;
	border-color: rgba(254, 243, 199, var(--tw-border-opacity));
}

.tw-border-yellow-200 {
	--tw-border-opacity: 1;
	border-color: rgba(253, 230, 138, var(--tw-border-opacity));
}

.\!tw-border-gray-300 {
	--tw-border-opacity: 1 !important;
	border-color: rgba(209, 213, 219, var(--tw-border-opacity)) !important;
}

.tw-border-orange-600 {
	--tw-border-opacity: 1;
	border-color: rgba(208, 56, 1, var(--tw-border-opacity));
}

.tw-border-orange-500 {
	--tw-border-opacity: 1;
	border-color: rgba(255, 90, 31, var(--tw-border-opacity));
}

.tw-border-l-transparent {
	border-left-color: transparent;
}

.tw-border-t-red-400 {
	--tw-border-opacity: 1;
	border-top-color: rgba(248, 113, 113, var(--tw-border-opacity));
}

.tw-border-r-red-400 {
	--tw-border-opacity: 1;
	border-right-color: rgba(248, 113, 113, var(--tw-border-opacity));
}

.tw-border-b-red-400 {
	--tw-border-opacity: 1;
	border-bottom-color: rgba(248, 113, 113, var(--tw-border-opacity));
}

.tw-border-t-gray-300 {
	--tw-border-opacity: 1;
	border-top-color: rgba(209, 213, 219, var(--tw-border-opacity));
}

.tw-border-r-gray-300 {
	--tw-border-opacity: 1;
	border-right-color: rgba(209, 213, 219, var(--tw-border-opacity));
}

.tw-border-b-gray-300 {
	--tw-border-opacity: 1;
	border-bottom-color: rgba(209, 213, 219, var(--tw-border-opacity));
}

.tw-bg-teal-100 {
	--tw-bg-opacity: 1;
	background-color: rgba(213, 245, 246, var(--tw-bg-opacity));
}

.tw-bg-orange-600 {
	--tw-bg-opacity: 1;
	background-color: rgba(208, 56, 1, var(--tw-bg-opacity));
}

.tw-bg-teal-600 {
	--tw-bg-opacity: 1;
	background-color: rgba(4, 116, 129, var(--tw-bg-opacity));
}

.tw-bg-red-600 {
	--tw-bg-opacity: 1;
	background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
}

.tw-bg-teal-200 {
	--tw-bg-opacity: 1;
	background-color: rgba(175, 236, 239, var(--tw-bg-opacity));
}

.tw-bg-white {
	--tw-bg-opacity: 1;
	background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.tw-bg-gray-100 {
	--tw-bg-opacity: 1;
	background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}

.tw-bg-orange-200 {
	--tw-bg-opacity: 1;
	background-color: rgba(252, 217, 189, var(--tw-bg-opacity));
}

.tw-bg-gray-200 {
	--tw-bg-opacity: 1;
	background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}

.tw-bg-red-50 {
	--tw-bg-opacity: 1;
	background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
}

.tw-bg-green-500 {
	--tw-bg-opacity: 1;
	background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
}

.tw-bg-gray-900 {
	--tw-bg-opacity: 1;
	background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
}

.tw-bg-gray-50 {
	--tw-bg-opacity: 1;
	background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}

.tw-bg-zinc-50 {
	--tw-bg-opacity: 1;
	background-color: rgba(250, 250, 250, var(--tw-bg-opacity));
}

.tw-bg-black {
	--tw-bg-opacity: 1;
	background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}

.tw-bg-red-100 {
	--tw-bg-opacity: 1;
	background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
}

.tw-bg-teal-50 {
	--tw-bg-opacity: 1;
	background-color: rgba(237, 250, 250, var(--tw-bg-opacity));
}

.tw-bg-zinc-100 {
	--tw-bg-opacity: 1;
	background-color: rgba(244, 244, 245, var(--tw-bg-opacity));
}

.tw-bg-yellow-300 {
	--tw-bg-opacity: 1;
	background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
}

.tw-bg-red-400 {
	--tw-bg-opacity: 1;
	background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
}

.tw-bg-teal-400 {
	--tw-bg-opacity: 1;
	background-color: rgba(22, 189, 202, var(--tw-bg-opacity));
}

.tw-bg-transparent {
	background-color: transparent;
}

.tw-bg-indigo-600 {
	--tw-bg-opacity: 1;
	background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
}

.tw-bg-gray-300 {
	--tw-bg-opacity: 1;
	background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
}

.tw-bg-red-500 {
	--tw-bg-opacity: 1;
	background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
}

.tw-bg-zinc-500 {
	--tw-bg-opacity: 1;
	background-color: rgba(113, 113, 122, var(--tw-bg-opacity));
}

.tw-bg-green-300 {
	--tw-bg-opacity: 1;
	background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
}

.tw-bg-blue-300 {
	--tw-bg-opacity: 1;
	background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
}

.tw-bg-indigo-300 {
	--tw-bg-opacity: 1;
	background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
}

.tw-bg-purple-300 {
	--tw-bg-opacity: 1;
	background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
}

.tw-bg-red-300 {
	--tw-bg-opacity: 1;
	background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
}

.tw-bg-pink-300 {
	--tw-bg-opacity: 1;
	background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
}

.tw-bg-gray-600 {
	--tw-bg-opacity: 1;
	background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
}

.tw-bg-gray-500 {
	--tw-bg-opacity: 1;
	background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
}

.tw-bg-yellow-100 {
	--tw-bg-opacity: 1;
	background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
}

.tw-bg-orange-100 {
	--tw-bg-opacity: 1;
	background-color: rgba(254, 236, 220, var(--tw-bg-opacity));
}

.tw-bg-blue-100 {
	--tw-bg-opacity: 1;
	background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
}

.tw-bg-red-800 {
	--tw-bg-opacity: 1;
	background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
}

.\!tw-bg-white {
	--tw-bg-opacity: 1 !important;
	background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
}

.tw-bg-teal-500 {
	--tw-bg-opacity: 1;
	background-color: rgba(6, 148, 162, var(--tw-bg-opacity));
}

.tw-bg-zinc-200 {
	--tw-bg-opacity: 1;
	background-color: rgba(228, 228, 231, var(--tw-bg-opacity));
}

.tw-bg-zinc-900 {
	--tw-bg-opacity: 1;
	background-color: rgba(24, 24, 27, var(--tw-bg-opacity));
}

.tw-bg-red-900 {
	--tw-bg-opacity: 1;
	background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
}

.tw-bg-teal-300 {
	--tw-bg-opacity: 1;
	background-color: rgba(126, 220, 226, var(--tw-bg-opacity));
}

.tw-bg-orange-50 {
	--tw-bg-opacity: 1;
	background-color: rgba(255, 248, 241, var(--tw-bg-opacity));
}

.tw-bg-green-100 {
	--tw-bg-opacity: 1;
	background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
}

.tw-bg-purple-100 {
	--tw-bg-opacity: 1;
	background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
}

.tw-bg-opacity-80 {
	--tw-bg-opacity: 0.8;
}

.tw-bg-opacity-50 {
	--tw-bg-opacity: 0.5;
}

.tw-bg-gradient-to-b {
	background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}

.tw-bg-gradient-to-r {
	background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.tw-bg-gradient-to-t {
	background-image: linear-gradient(to top, var(--tw-gradient-stops));
}

.tw-from-yellow-50 {
	--tw-gradient-from: #fffbeb;
	--tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 251, 235, 0));
}

.tw-from-zinc-800 {
	--tw-gradient-from: #27272a;
	--tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(39, 39, 42, 0));
}

.tw-from-zinc-100 {
	--tw-gradient-from: #f4f4f5;
	--tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 244, 245, 0));
}

.tw-to-white {
	--tw-gradient-to: #fff;
}

.tw-to-zinc-900 {
	--tw-gradient-to: #18181b;
}

.tw-bg-cover {
	background-size: cover;
}

.tw-bg-right-bottom {
	background-position: right bottom;
}

.tw-bg-center {
	background-position: center;
}

.tw-bg-no-repeat {
	background-repeat: no-repeat;
}

.tw-fill-current {
	fill: currentColor;
}

.tw-stroke-current {
	stroke: currentColor;
}

.tw-object-cover {
	-o-object-fit: cover;
	   object-fit: cover;
}

.tw-p-2 {
	padding: 0.5rem;
}

.tw-p-3 {
	padding: 0.75rem;
}

.tw-p-px {
	padding: 1px;
}

.tw-p-4 {
	padding: 1rem;
}

.tw-p-8 {
	padding: 2rem;
}

.tw-p-0 {
	padding: 0px;
}

.tw-p-1 {
	padding: 0.25rem;
}

.tw-p-16 {
	padding: 4rem;
}

.tw-p-6 {
	padding: 1.5rem;
}

.tw-p-2\.5 {
	padding: 0.625rem;
}

.tw-p-12 {
	padding: 3rem;
}

.tw-p-1\.5 {
	padding: 0.375rem;
}

.tw-px-6 {
	padding-left: 1.5rem;
	padding-right: 1.5rem;
}

.tw-px-2 {
	padding-left: 0.5rem;
	padding-right: 0.5rem;
}

.tw-py-1 {
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
}

.tw-px-4 {
	padding-left: 1rem;
	padding-right: 1rem;
}

.tw-py-2 {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
}

.tw-px-2\.5 {
	padding-left: 0.625rem;
	padding-right: 0.625rem;
}

.tw-py-3 {
	padding-top: 0.75rem;
	padding-bottom: 0.75rem;
}

.tw-px-8 {
	padding-left: 2rem;
	padding-right: 2rem;
}

.tw-py-8 {
	padding-top: 2rem;
	padding-bottom: 2rem;
}

.tw-px-3 {
	padding-left: 0.75rem;
	padding-right: 0.75rem;
}

.tw-px-1 {
	padding-left: 0.25rem;
	padding-right: 0.25rem;
}

.tw-py-4 {
	padding-top: 1rem;
	padding-bottom: 1rem;
}

.tw-py-64 {
	padding-top: 16rem;
	padding-bottom: 16rem;
}

.tw-px-5 {
	padding-left: 1.25rem;
	padding-right: 1.25rem;
}

.tw-px-0\.5 {
	padding-left: 0.125rem;
	padding-right: 0.125rem;
}

.tw-px-0 {
	padding-left: 0px;
	padding-right: 0px;
}

.tw-py-0 {
	padding-top: 0px;
	padding-bottom: 0px;
}

.tw-py-6 {
	padding-top: 1.5rem;
	padding-bottom: 1.5rem;
}

.tw-px-12 {
	padding-left: 3rem;
	padding-right: 3rem;
}

.tw-py-10 {
	padding-top: 2.5rem;
	padding-bottom: 2.5rem;
}

.tw-py-1\.5 {
	padding-top: 0.375rem;
	padding-bottom: 0.375rem;
}

.tw-py-2\.5 {
	padding-top: 0.625rem;
	padding-bottom: 0.625rem;
}

.tw-py-5 {
	padding-top: 1.25rem;
	padding-bottom: 1.25rem;
}

.tw-py-0\.5 {
	padding-top: 0.125rem;
	padding-bottom: 0.125rem;
}

.tw-py-12 {
	padding-top: 3rem;
	padding-bottom: 3rem;
}

.tw-py-20 {
	padding-top: 5rem;
	padding-bottom: 5rem;
}

.tw-px-1\.5 {
	padding-left: 0.375rem;
	padding-right: 0.375rem;
}

.\!tw-px-3 {
	padding-left: 0.75rem !important;
	padding-right: 0.75rem !important;
}

.\!tw-py-2 {
	padding-top: 0.5rem !important;
	padding-bottom: 0.5rem !important;
}

.tw-pt-8 {
	padding-top: 2rem;
}

.tw-pt-4 {
	padding-top: 1rem;
}

.tw-pl-4 {
	padding-left: 1rem;
}

.tw-pr-1 {
	padding-right: 0.25rem;
}

.tw-pl-1 {
	padding-left: 0.25rem;
}

.tw-pt-16 {
	padding-top: 4rem;
}

.tw-pt-5 {
	padding-top: 1.25rem;
}

.tw-pr-2 {
	padding-right: 0.5rem;
}

.tw-pt-6 {
	padding-top: 1.5rem;
}

.tw-pl-5 {
	padding-left: 1.25rem;
}

.tw-pt-1 {
	padding-top: 0.25rem;
}

.tw-pb-1 {
	padding-bottom: 0.25rem;
}

.tw-pr-4 {
	padding-right: 1rem;
}

.tw-pb-3 {
	padding-bottom: 0.75rem;
}

.tw-pt-2 {
	padding-top: 0.5rem;
}

.tw-pb-2 {
	padding-bottom: 0.5rem;
}

.tw-pl-3 {
	padding-left: 0.75rem;
}

.tw-pl-8 {
	padding-left: 2rem;
}

.tw-pr-3 {
	padding-right: 0.75rem;
}

.tw-pl-0 {
	padding-left: 0px;
}

.tw-pl-2 {
	padding-left: 0.5rem;
}

.tw-pt-3 {
	padding-top: 0.75rem;
}

.tw-pt-10 {
	padding-top: 2.5rem;
}

.tw-pb-20 {
	padding-bottom: 5rem;
}

.tw-pt-14 {
	padding-top: 3.5rem;
}

.tw-pb-4 {
	padding-bottom: 1rem;
}

.tw-pb-5 {
	padding-bottom: 1.25rem;
}

.tw-pl-2\.5 {
	padding-left: 0.625rem;
}

.tw-pl-6 {
	padding-left: 1.5rem;
}

.tw-pr-8 {
	padding-right: 2rem;
}

.tw-pr-6 {
	padding-right: 1.5rem;
}

.tw-pl-10 {
	padding-left: 2.5rem;
}

.tw-pr-12 {
	padding-right: 3rem;
}

.tw-pr-5 {
	padding-right: 1.25rem;
}

.tw-pr-10 {
	padding-right: 2.5rem;
}

.tw-pr-7 {
	padding-right: 1.75rem;
}

.tw-pb-0 {
	padding-bottom: 0px;
}

.tw-pt-1\.5 {
	padding-top: 0.375rem;
}

.tw-pt-20 {
	padding-top: 5rem;
}

.tw-pr-16 {
	padding-right: 4rem;
}

.tw-pl-1\.5 {
	padding-left: 0.375rem;
}

.tw-pl-24 {
	padding-left: 6rem;
}

.tw-pt-0 {
	padding-top: 0px;
}

.tw-pb-10 {
	padding-bottom: 2.5rem;
}

.tw-pl-7 {
	padding-left: 1.75rem;
}

.tw-pb-6 {
	padding-bottom: 1.5rem;
}

.tw-pr-0 {
	padding-right: 0px;
}

.tw-pl-12 {
	padding-left: 3rem;
}

.tw-pr-1\.5 {
	padding-right: 0.375rem;
}

.tw-pr-2\.5 {
	padding-right: 0.625rem;
}

.tw-pl-0\.5 {
	padding-left: 0.125rem;
}

.tw-pb-12 {
	padding-bottom: 3rem;
}

.tw-pt-0\.5 {
	padding-top: 0.125rem;
}

.tw-pt-12 {
	padding-top: 3rem;
}

.tw-text-left {
	text-align: left;
}

.tw-text-center {
	text-align: center;
}

.tw-text-right {
	text-align: right;
}

.tw-text-justify {
	text-align: justify;
}

.\!tw-align-top {
	vertical-align: top !important;
}

.tw-align-middle {
	vertical-align: middle;
}

.tw-align-bottom {
	vertical-align: bottom;
}

.tw-align-text-top {
	vertical-align: text-top;
}

.tw-align-text-bottom {
	vertical-align: text-bottom;
}

.tw-font-sans {
	font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.tw-text-sm {
	font-size: 0.875rem;
	line-height: 1.25rem;
}

.tw-text-base {
	font-size: 1rem;
	line-height: 1.5rem;
}

.tw-text-2xl {
	font-size: 1.5rem;
	line-height: 2rem;
}

.tw-text-lg {
	font-size: 1.125rem;
	line-height: 1.75rem;
}

.tw-text-xs {
	font-size: 0.75rem;
	line-height: 1rem;
}

.tw-text-xl {
	font-size: 1.25rem;
	line-height: 1.75rem;
}

.tw-text-3xl {
	font-size: 1.875rem;
	line-height: 2.25rem;
}

.tw-text-4xl {
	font-size: 2.25rem;
	line-height: 2.5rem;
}

.tw-text-6xl {
	font-size: 3.75rem;
	line-height: 1;
}

.tw-text-5xl {
	font-size: 3rem;
	line-height: 1;
}

.tw-text-7xl {
	font-size: 4.5rem;
	line-height: 1;
}

.\!tw-text-xs {
	font-size: 0.75rem !important;
	line-height: 1rem !important;
}

.tw-font-medium {
	font-weight: 500;
}

.tw-font-bold {
	font-weight: 700;
}

.tw-font-normal {
	font-weight: 400;
}

.tw-font-thin {
	font-weight: 100;
}

.tw-font-semibold {
	font-weight: 600;
}

.tw-font-extrabold {
	font-weight: 800;
}

.tw-uppercase {
	text-transform: uppercase;
}

.tw-capitalize {
	text-transform: capitalize;
}

.tw-normal-case {
	text-transform: none;
}

.tw-italic {
	font-style: italic;
}

.tw-leading-4 {
	line-height: 1rem;
}

.tw-leading-5 {
	line-height: 1.25rem;
}

.tw-leading-8 {
	line-height: 2rem;
}

.tw-leading-7 {
	line-height: 1.75rem;
}

.tw-leading-6 {
	line-height: 1.5rem;
}

.tw-leading-none {
	line-height: 1;
}

.tw-leading-loose {
	line-height: 2;
}

.tw-leading-tight {
	line-height: 1.25;
}

.tw-leading-normal {
	line-height: 1.5;
}

.tw-leading-relaxed {
	line-height: 1.625;
}

.tw-leading-9 {
	line-height: 2.25rem;
}

.tw-tracking-wide {
	letter-spacing: 0.025em;
}

.tw-tracking-wider {
	letter-spacing: 0.05em;
}

.tw-tracking-widest {
	letter-spacing: 0.1em;
}

.tw-tracking-normal {
	letter-spacing: 0em;
}

.tw-tracking-tight {
	letter-spacing: -0.025em;
}

.tw-text-red-500 {
	--tw-text-opacity: 1;
	color: rgba(239, 68, 68, var(--tw-text-opacity));
}

.tw-text-green-500 {
	--tw-text-opacity: 1;
	color: rgba(16, 185, 129, var(--tw-text-opacity));
}

.tw-text-gray-500 {
	--tw-text-opacity: 1;
	color: rgba(107, 114, 128, var(--tw-text-opacity));
}

.tw-text-teal-700 {
	--tw-text-opacity: 1;
	color: rgba(3, 102, 114, var(--tw-text-opacity));
}

.tw-text-white {
	--tw-text-opacity: 1;
	color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.tw-text-gray-600 {
	--tw-text-opacity: 1;
	color: rgba(75, 85, 99, var(--tw-text-opacity));
}

.tw-text-zinc-200 {
	--tw-text-opacity: 1;
	color: rgba(228, 228, 231, var(--tw-text-opacity));
}

.tw-text-black {
	--tw-text-opacity: 1;
	color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.tw-text-teal-600 {
	--tw-text-opacity: 1;
	color: rgba(4, 116, 129, var(--tw-text-opacity));
}

.tw-text-zinc-500 {
	--tw-text-opacity: 1;
	color: rgba(113, 113, 122, var(--tw-text-opacity));
}

.tw-text-teal-500 {
	--tw-text-opacity: 1;
	color: rgba(6, 148, 162, var(--tw-text-opacity));
}

.tw-text-gray-300 {
	--tw-text-opacity: 1;
	color: rgba(209, 213, 219, var(--tw-text-opacity));
}

.tw-text-gray-100 {
	--tw-text-opacity: 1;
	color: rgba(243, 244, 246, var(--tw-text-opacity));
}

.tw-text-gray-700 {
	--tw-text-opacity: 1;
	color: rgba(55, 65, 81, var(--tw-text-opacity));
}

.tw-text-gray-400 {
	--tw-text-opacity: 1;
	color: rgba(156, 163, 175, var(--tw-text-opacity));
}

.tw-text-\[\#a94442\] {
	--tw-text-opacity: 1;
	color: rgba(169, 68, 66, var(--tw-text-opacity));
}

.tw-text-gray-900 {
	--tw-text-opacity: 1;
	color: rgba(17, 24, 39, var(--tw-text-opacity));
}

.tw-text-red-400 {
	--tw-text-opacity: 1;
	color: rgba(248, 113, 113, var(--tw-text-opacity));
}

.tw-text-red-800 {
	--tw-text-opacity: 1;
	color: rgba(153, 27, 27, var(--tw-text-opacity));
}

.tw-text-red-700 {
	--tw-text-opacity: 1;
	color: rgba(185, 28, 28, var(--tw-text-opacity));
}

.tw-text-gray-800 {
	--tw-text-opacity: 1;
	color: rgba(31, 41, 55, var(--tw-text-opacity));
}

.tw-text-teal-800 {
	--tw-text-opacity: 1;
	color: rgba(5, 80, 92, var(--tw-text-opacity));
}

.tw-text-zinc-400 {
	--tw-text-opacity: 1;
	color: rgba(161, 161, 170, var(--tw-text-opacity));
}

.tw-text-zinc-700 {
	--tw-text-opacity: 1;
	color: rgba(63, 63, 70, var(--tw-text-opacity));
}

.tw-text-orange-400 {
	--tw-text-opacity: 1;
	color: rgba(255, 138, 76, var(--tw-text-opacity));
}

.tw-text-yellow-500 {
	--tw-text-opacity: 1;
	color: rgba(245, 158, 11, var(--tw-text-opacity));
}

.tw-text-gray-200 {
	--tw-text-opacity: 1;
	color: rgba(229, 231, 235, var(--tw-text-opacity));
}

.tw-text-zinc-800 {
	--tw-text-opacity: 1;
	color: rgba(39, 39, 42, var(--tw-text-opacity));
}

.tw-text-red-600 {
	--tw-text-opacity: 1;
	color: rgba(220, 38, 38, var(--tw-text-opacity));
}

.tw-text-teal-400 {
	--tw-text-opacity: 1;
	color: rgba(22, 189, 202, var(--tw-text-opacity));
}

.tw-text-zinc-600 {
	--tw-text-opacity: 1;
	color: rgba(82, 82, 91, var(--tw-text-opacity));
}

.tw-text-zinc-900 {
	--tw-text-opacity: 1;
	color: rgba(24, 24, 27, var(--tw-text-opacity));
}

.tw-text-blue-200 {
	--tw-text-opacity: 1;
	color: rgba(191, 219, 254, var(--tw-text-opacity));
}

.tw-text-zinc-300 {
	--tw-text-opacity: 1;
	color: rgba(212, 212, 216, var(--tw-text-opacity));
}

.tw-text-red-900 {
	--tw-text-opacity: 1;
	color: rgba(127, 29, 29, var(--tw-text-opacity));
}

.tw-text-yellow-400 {
	--tw-text-opacity: 1;
	color: rgba(251, 191, 36, var(--tw-text-opacity));
}

.tw-text-green-600 {
	--tw-text-opacity: 1;
	color: rgba(5, 150, 105, var(--tw-text-opacity));
}

.tw-text-blue-600 {
	--tw-text-opacity: 1;
	color: rgba(37, 99, 235, var(--tw-text-opacity));
}

.tw-text-teal-900 {
	--tw-text-opacity: 1;
	color: rgba(1, 68, 81, var(--tw-text-opacity));
}

.tw-text-yellow-700 {
	--tw-text-opacity: 1;
	color: rgba(180, 83, 9, var(--tw-text-opacity));
}

.tw-text-orange-600 {
	--tw-text-opacity: 1;
	color: rgba(208, 56, 1, var(--tw-text-opacity));
}

.tw-text-teal-300 {
	--tw-text-opacity: 1;
	color: rgba(126, 220, 226, var(--tw-text-opacity));
}

.tw-text-indigo-600 {
	--tw-text-opacity: 1;
	color: rgba(79, 70, 229, var(--tw-text-opacity));
}

.tw-text-red-300 {
	--tw-text-opacity: 1;
	color: rgba(252, 165, 165, var(--tw-text-opacity));
}

.tw-text-transparent {
	color: transparent;
}

.tw-text-gray-50 {
	--tw-text-opacity: 1;
	color: rgba(249, 250, 251, var(--tw-text-opacity));
}

.tw-text-red-100 {
	--tw-text-opacity: 1;
	color: rgba(254, 226, 226, var(--tw-text-opacity));
}

.tw-text-yellow-600 {
	--tw-text-opacity: 1;
	color: rgba(217, 119, 6, var(--tw-text-opacity));
}

.\!tw-text-gray-700 {
	--tw-text-opacity: 1 !important;
	color: rgba(55, 65, 81, var(--tw-text-opacity)) !important;
}

.tw-text-green-400 {
	--tw-text-opacity: 1;
	color: rgba(52, 211, 153, var(--tw-text-opacity));
}

.tw-text-green-300 {
	--tw-text-opacity: 1;
	color: rgba(110, 231, 183, var(--tw-text-opacity));
}

.tw-text-orange-700 {
	--tw-text-opacity: 1;
	color: rgba(180, 52, 3, var(--tw-text-opacity));
}

.tw-text-orange-800 {
	--tw-text-opacity: 1;
	color: rgba(138, 44, 13, var(--tw-text-opacity));
}

.tw-text-green-700 {
	--tw-text-opacity: 1;
	color: rgba(4, 120, 87, var(--tw-text-opacity));
}

.tw-text-orange-500 {
	--tw-text-opacity: 1;
	color: rgba(255, 90, 31, var(--tw-text-opacity));
}

.tw-text-yellow-200 {
	--tw-text-opacity: 1;
	color: rgba(253, 230, 138, var(--tw-text-opacity));
}

.tw-text-blue-700 {
	--tw-text-opacity: 1;
	color: rgba(29, 78, 216, var(--tw-text-opacity));
}

.tw-text-purple-700 {
	--tw-text-opacity: 1;
	color: rgba(109, 40, 217, var(--tw-text-opacity));
}

.tw-text-blue-500 {
	--tw-text-opacity: 1;
	color: rgba(59, 130, 246, var(--tw-text-opacity));
}

.tw-underline {
	text-decoration: underline;
}

.tw-no-underline {
	text-decoration: none;
}

.tw-antialiased {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.tw-placeholder-gray-400::-moz-placeholder {
	--tw-placeholder-opacity: 1;
	color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}

.tw-placeholder-gray-400::placeholder {
	--tw-placeholder-opacity: 1;
	color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}

.tw-placeholder-gray-300::-moz-placeholder {
	--tw-placeholder-opacity: 1;
	color: rgba(209, 213, 219, var(--tw-placeholder-opacity));
}

.tw-placeholder-gray-300::placeholder {
	--tw-placeholder-opacity: 1;
	color: rgba(209, 213, 219, var(--tw-placeholder-opacity));
}

.tw-placeholder-zinc-400::-moz-placeholder {
	--tw-placeholder-opacity: 1;
	color: rgba(161, 161, 170, var(--tw-placeholder-opacity));
}

.tw-placeholder-zinc-400::placeholder {
	--tw-placeholder-opacity: 1;
	color: rgba(161, 161, 170, var(--tw-placeholder-opacity));
}

.tw-placeholder-gray-600::-moz-placeholder {
	--tw-placeholder-opacity: 1;
	color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
}

.tw-placeholder-gray-600::placeholder {
	--tw-placeholder-opacity: 1;
	color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
}

.tw-placeholder-red-300::-moz-placeholder {
	--tw-placeholder-opacity: 1;
	color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
}

.tw-placeholder-red-300::placeholder {
	--tw-placeholder-opacity: 1;
	color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
}

.tw-placeholder-zinc-200::-moz-placeholder {
	--tw-placeholder-opacity: 1;
	color: rgba(228, 228, 231, var(--tw-placeholder-opacity));
}

.tw-placeholder-zinc-200::placeholder {
	--tw-placeholder-opacity: 1;
	color: rgba(228, 228, 231, var(--tw-placeholder-opacity));
}

.tw-opacity-0 {
	opacity: 0;
}

.tw-opacity-100 {
	opacity: 1;
}

.tw-opacity-50 {
	opacity: 0.5;
}

.tw-opacity-95 {
	opacity: 0.95;
}

.tw-opacity-25 {
	opacity: 0.25;
}

.tw-shadow-sm {
	--tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
	box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
	box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.tw-shadow-lg {
	--tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
	box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
	box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.tw-shadow-none {
	--tw-shadow: 0 0 #0000;
	box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
	box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.tw-shadow-md {
	--tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
	box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
	box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.tw-shadow {
	--tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
	box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
	box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.tw-shadow-inner {
	--tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
	box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
	box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.tw-shadow-xl {
	--tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
	box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
	box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.tw-shadow-2xl {
	--tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
	box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
	box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.tw-ring-1 {
	--tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
	--tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
	box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), 0 0 #0000;
	box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.\!tw-ring-0 {
	--tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
	--tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
	box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), 0 0 #0000 !important;
	box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
}

.tw-ring-inset {
	--tw-ring-inset: inset;
}

.tw-ring-black {
	--tw-ring-opacity: 1;
	--tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
}

.tw-ring-gray-300 {
	--tw-ring-opacity: 1;
	--tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity));
}

.tw-ring-red-200 {
	--tw-ring-opacity: 1;
	--tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity));
}

.tw-ring-opacity-5 {
	--tw-ring-opacity: 0.05;
}

.tw-ring-offset-red-200 {
	--tw-ring-offset-color: #fecaca;
}

.tw-transition {
	transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
	transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
	transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	transition-duration: 150ms;
}

.tw-transition-colors {
	transition-property: background-color, border-color, color, fill, stroke;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	transition-duration: 150ms;
}

.tw-transition-all {
	transition-property: all;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	transition-duration: 150ms;
}

.tw-transition-opacity {
	transition-property: opacity;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	transition-duration: 150ms;
}

.\!tw-transition-all {
	transition-property: all !important;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
	transition-duration: 150ms !important;
}

.tw-duration-150 {
	transition-duration: 150ms;
}

.tw-duration-100 {
	transition-duration: 100ms;
}

.tw-duration-75 {
	transition-duration: 75ms;
}

.tw-duration-300 {
	transition-duration: 300ms;
}

.tw-duration-200 {
	transition-duration: 200ms;
}

.\!tw-duration-100 {
	transition-duration: 100ms !important;
}

.tw-duration-500 {
	transition-duration: 500ms;
}

.tw-ease-in-out {
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.tw-ease-out {
	transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}

.tw-ease-in {
	transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}

.\!tw-ease-out {
	transition-timing-function: cubic-bezier(0, 0, 0.2, 1) !important;
}

.tw-form-input {
	border-radius: 0.375rem;
	border-width: 1px;
	border-style: solid;
	--tw-border-opacity: 1;
	border-color: rgba(209, 213, 219, var(--tw-border-opacity));
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
}

.tw-form-input:focus {
	--tw-border-opacity: 1;
	border-color: rgba(147, 197, 253, var(--tw-border-opacity));
	outline: 2px solid transparent;
	outline-offset: 2px;
	--tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
	--tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
	box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), 0 0 #0000;
	box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
	--tw-ring-opacity: 1;
	--tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
	--tw-ring-opacity: 0.5;
}

.field_with_errors .tw-form-input {
	--tw-border-opacity: 1;
	border-color: rgba(239, 68, 68, var(--tw-border-opacity));
}

.tw-checkbox {
	border-radius: 0.25rem;
	border-width: 1px;
	border-style: solid;
	--tw-border-opacity: 1;
	border-color: rgba(209, 213, 219, var(--tw-border-opacity));
	--tw-text-opacity: 1;
	color: rgba(4, 116, 129, var(--tw-text-opacity));
}

.tw-checkbox:focus {
	--tw-ring-opacity: 1;
	--tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
}

.tw-radio {
	margin-top: 0.125rem;
	margin-bottom: 0px;
	height: 1rem;
	width: 1rem;
	cursor: pointer;
	border-width: 1px;
	border-style: solid;
	--tw-border-opacity: 1;
	border-color: rgba(209, 213, 219, var(--tw-border-opacity));
	--tw-text-opacity: 1;
	color: rgba(4, 116, 129, var(--tw-text-opacity));
}

.tw-radio:focus {
	--tw-ring-opacity: 1;
	--tw-ring-color: rgba(6, 148, 162, var(--tw-ring-opacity));
}

.pagy-nav, .pagy-nav-js {
	display: flex;
	align-items: center;
}

.pagy-nav > :not([hidden]) ~ :not([hidden]), .pagy-nav-js > :not([hidden]) ~ :not([hidden]) {
	--tw-space-x-reverse: 0;
	margin-right: calc(0.25rem * var(--tw-space-x-reverse));
	margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.pagy-nav, .pagy-nav-js {
	padding-left: 0.75rem;
}

.tw-radio-label {
	margin-bottom: 0px;
}

.hidden {
	display: none;
}

input.tw-radio {
	margin-top: 0px;
}

.pagy-nav .page a, .pagy-nav .page.active, .pagy-nav .page.prev.disabled, .pagy-nav .page.next.disabled, .pagy-nav-js .page a, .pagy-nav-js .page.active, .pagy-nav-js .page.prev.disabled, .pagy-nav-js .page.next.disabled {
	display: block;
	border-radius: 0.375rem;
	padding-left: 0.75rem;
	padding-right: 0.75rem;
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
	--tw-text-opacity: 1;
	color: rgba(107, 114, 128, var(--tw-text-opacity));
}

.pagy-nav .page a:hover, .pagy-nav .page.active:hover, .pagy-nav .page.prev.disabled:hover, .pagy-nav .page.next.disabled:hover, .pagy-nav-js .page a:hover, .pagy-nav-js .page.active:hover, .pagy-nav-js .page.prev.disabled:hover, .pagy-nav-js .page.next.disabled:hover {
	display: block;
	--tw-bg-opacity: 1;
	background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
	padding-left: 0.75rem;
	padding-right: 0.75rem;
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
}

.pagy-nav .page a:active, .pagy-nav .page.active:active, .pagy-nav .page.prev.disabled:active, .pagy-nav .page.next.disabled:active, .pagy-nav-js .page a:active, .pagy-nav-js .page.active:active, .pagy-nav-js .page.prev.disabled:active, .pagy-nav-js .page.next.disabled:active {
	--tw-bg-opacity: 1;
	background-color: rgba(22, 189, 202, var(--tw-bg-opacity));
	--tw-text-opacity: 1;
	color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.pagy-nav .page.prev.disabled, .pagy-nav .page.next.disabled, .pagy-nav-js .page.prev.disabled, .pagy-nav-js .page.next.disabled {
	cursor: default;
	--tw-text-opacity: 1;
	color: rgba(156, 163, 175, var(--tw-text-opacity));
}

.pagy-nav .page.prev.disabled:hover, .pagy-nav .page.next.disabled:hover, .pagy-nav-js .page.prev.disabled:hover, .pagy-nav-js .page.next.disabled:hover {
	--tw-bg-opacity: 1;
	background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
	--tw-text-opacity: 1;
	color: rgba(31, 41, 55, var(--tw-text-opacity));
}

.pagy-nav .page.prev.disabled:active, .pagy-nav .page.next.disabled:active, .pagy-nav-js .page.prev.disabled:active, .pagy-nav-js .page.next.disabled:active {
	--tw-bg-opacity: 1;
	background-color: rgba(22, 189, 202, var(--tw-bg-opacity));
	--tw-text-opacity: 1;
	color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.pagy-nav .page.active, .pagy-nav-js .page.active {
	cursor: default;
	--tw-bg-opacity: 1;
	background-color: rgba(22, 189, 202, var(--tw-bg-opacity));
	--tw-text-opacity: 1;
	color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.pagy-nav .page.active:hover, .pagy-nav-js .page.active:hover {
	--tw-bg-opacity: 1;
	background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
	--tw-text-opacity: 1;
	color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.pagy-nav .page.active:active, .pagy-nav-js .page.active:active {
	--tw-bg-opacity: 1;
	background-color: rgba(22, 189, 202, var(--tw-bg-opacity));
	--tw-text-opacity: 1;
	color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.pagination {
	margin-top: 0px;
	margin-bottom: 0px;
}

.pagy-combo-nav-js {
	display: flex;
	max-width: -webkit-max-content;
	max-width: -moz-max-content;
	max-width: max-content;
	border-radius: 9999px;
	--tw-bg-opacity: 1;
	background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
	padding-left: 0.75rem;
	padding-right: 0.75rem;
	font-weight: 600;
	--tw-text-opacity: 1;
	color: rgba(107, 114, 128, var(--tw-text-opacity));
	--tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
	box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
	box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.pagy-combo-nav-js .pagy-combo-input {
	border-radius: 0.125rem;
	--tw-bg-opacity: 1;
	background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
	padding-left: 0.5rem;
	padding-right: 0.5rem;
}

.pagy-combo-nav-js .page.prev:hover, .pagy-combo-nav-js .page.next:hover {
	--tw-text-opacity: 1;
	color: rgba(55, 65, 81, var(--tw-text-opacity));
}

.pagy-combo-nav-js .page.prev:active, .pagy-combo-nav-js .page.next:active {
	--tw-text-opacity: 1;
	color: rgba(55, 65, 81, var(--tw-text-opacity));
}

.pagy-combo-nav-js .page.prev.disabled, .pagy-combo-nav-js .page.next.disabled {
	cursor: default;
	--tw-text-opacity: 1;
	color: rgba(156, 163, 175, var(--tw-text-opacity));
}

.pagy-info {
	--tw-text-opacity: 1;
	color: rgba(107, 114, 128, var(--tw-text-opacity));
}

input[type="text"]:disabled {
	--tw-bg-opacity: 1;
	background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
	--tw-text-opacity: 1;
	color: rgba(161, 161, 170, var(--tw-text-opacity));
}

input[type="file"]::-webkit-file-upload-button:focus {
	background-color: #d5f5f6;
  border: 1px solid #0694a2;
}

input[type="file"]::file-selector-button:focus {
	background-color: #d5f5f6;
  border: 1px solid #0694a2;
}

input[type="file"]:focus:focus {
	border-color: transparent;
	outline: 2px solid transparent;
	outline-offset: 2px;
}

input[type="file"]:hover {
  color: #0694a2;
}

input[type="file"]::-webkit-file-upload-button {
	margin-right: 1.5rem;
	cursor: pointer;
	border-radius: 0.375rem;
	border-width: 1px;
	border-style: solid;
	--tw-border-opacity: 1;
	border-color: rgba(209, 213, 219, var(--tw-border-opacity));
	--tw-bg-opacity: 1;
	background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	padding-left: 1rem;
	padding-right: 1rem;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 400;
}

input[type=file]::-webkit-file-upload-button, input[type="file"]::file-selector-button {
	margin-right: 1.5rem;
	cursor: pointer;
	border-radius: 0.375rem;
	border-width: 1px;
	border-style: solid;
	--tw-border-opacity: 1;
	border-color: rgba(209, 213, 219, var(--tw-border-opacity));
	--tw-bg-opacity: 1;
	background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	padding-left: 1rem;
	padding-right: 1rem;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 400;
}

/* Replicating `data-disable-with` functionality */

/* from `rails-ujs` using Turbo */

button .show-when-disabled {
  display: none;
}

button[disabled] .show-when-disabled {
  display: inline;
  display: initial;
}

button .show-when-enabled {
  display: inline;
  display: initial;
}

button[disabled] .show-when-enabled {
  display: none;
}

.first\:tw-mt-0:first-child {
	margin-top: 0px;
}

.focus-within\:tw-z-10:focus-within {
	z-index: 10;
}

.focus-within\:tw-ring-2:focus-within {
	--tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
	--tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
	box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), 0 0 #0000;
	box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus-within\:tw-ring-indigo-500:focus-within {
	--tw-ring-opacity: 1;
	--tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
}

.focus-within\:tw-ring-offset-2:focus-within {
	--tw-ring-offset-width: 2px;
}

.hover\:tw-cursor-pointer:hover {
	cursor: pointer;
}

.hover\:tw-border:hover {
	border-width: 1px;
}

.hover\:tw-border-solid:hover {
	border-style: solid;
}

.hover\:tw-border-teal-200:hover {
	--tw-border-opacity: 1;
	border-color: rgba(175, 236, 239, var(--tw-border-opacity));
}

.hover\:tw-border-gray-400:hover {
	--tw-border-opacity: 1;
	border-color: rgba(156, 163, 175, var(--tw-border-opacity));
}

.hover\:tw-border-gray-200:hover {
	--tw-border-opacity: 1;
	border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}

.hover\:tw-border-teal-400:hover {
	--tw-border-opacity: 1;
	border-color: rgba(22, 189, 202, var(--tw-border-opacity));
}

.hover\:tw-border-gray-300:hover {
	--tw-border-opacity: 1;
	border-color: rgba(209, 213, 219, var(--tw-border-opacity));
}

.hover\:tw-border-teal-500:hover {
	--tw-border-opacity: 1;
	border-color: rgba(6, 148, 162, var(--tw-border-opacity));
}

.hover\:tw-border-red-500:hover {
	--tw-border-opacity: 1;
	border-color: rgba(239, 68, 68, var(--tw-border-opacity));
}

.hover\:tw-bg-teal-200:hover {
	--tw-bg-opacity: 1;
	background-color: rgba(175, 236, 239, var(--tw-bg-opacity));
}

.hover\:tw-bg-orange-500:hover {
	--tw-bg-opacity: 1;
	background-color: rgba(255, 90, 31, var(--tw-bg-opacity));
}

.hover\:tw-bg-teal-500:hover {
	--tw-bg-opacity: 1;
	background-color: rgba(6, 148, 162, var(--tw-bg-opacity));
}

.hover\:tw-bg-teal-300:hover {
	--tw-bg-opacity: 1;
	background-color: rgba(126, 220, 226, var(--tw-bg-opacity));
}

.hover\:tw-bg-gray-100:hover {
	--tw-bg-opacity: 1;
	background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}

.hover\:tw-bg-orange-900:hover {
	--tw-bg-opacity: 1;
	background-color: rgba(115, 35, 13, var(--tw-bg-opacity));
}

.hover\:tw-bg-gray-400:hover {
	--tw-bg-opacity: 1;
	background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
}

.hover\:tw-bg-gray-700:hover {
	--tw-bg-opacity: 1;
	background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
}

.hover\:tw-bg-gray-300:hover {
	--tw-bg-opacity: 1;
	background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
}

.hover\:tw-bg-teal-700:hover {
	--tw-bg-opacity: 1;
	background-color: rgba(3, 102, 114, var(--tw-bg-opacity));
}

.hover\:tw-bg-gray-50:hover {
	--tw-bg-opacity: 1;
	background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}

.hover\:tw-bg-gray-200:hover {
	--tw-bg-opacity: 1;
	background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}

.hover\:tw-bg-teal-100:hover {
	--tw-bg-opacity: 1;
	background-color: rgba(213, 245, 246, var(--tw-bg-opacity));
}

.hover\:tw-bg-white:hover {
	--tw-bg-opacity: 1;
	background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.hover\:tw-bg-zinc-50:hover {
	--tw-bg-opacity: 1;
	background-color: rgba(250, 250, 250, var(--tw-bg-opacity));
}

.hover\:tw-bg-teal-900:hover {
	--tw-bg-opacity: 1;
	background-color: rgba(1, 68, 81, var(--tw-bg-opacity));
}

.hover\:tw-bg-red-600:hover {
	--tw-bg-opacity: 1;
	background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
}

.hover\:tw-bg-teal-600:hover {
	--tw-bg-opacity: 1;
	background-color: rgba(4, 116, 129, var(--tw-bg-opacity));
}

.hover\:tw-bg-teal-50:hover {
	--tw-bg-opacity: 1;
	background-color: rgba(237, 250, 250, var(--tw-bg-opacity));
}

.hover\:tw-bg-indigo-700:hover {
	--tw-bg-opacity: 1;
	background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
}

.hover\:tw-bg-red-200:hover {
	--tw-bg-opacity: 1;
	background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
}

.hover\:tw-bg-indigo-500:hover {
	--tw-bg-opacity: 1;
	background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
}

.hover\:tw-bg-gray-500:hover {
	--tw-bg-opacity: 1;
	background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
}

.hover\:tw-bg-green-400:hover {
	--tw-bg-opacity: 1;
	background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
}

.hover\:tw-bg-red-50:hover {
	--tw-bg-opacity: 1;
	background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
}

.hover\:tw-bg-teal-800:hover {
	--tw-bg-opacity: 1;
	background-color: rgba(5, 80, 92, var(--tw-bg-opacity));
}

.hover\:tw-text-teal-800:hover {
	--tw-text-opacity: 1;
	color: rgba(5, 80, 92, var(--tw-text-opacity));
}

.hover\:tw-text-white:hover {
	--tw-text-opacity: 1;
	color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.hover\:tw-text-gray-600:hover {
	--tw-text-opacity: 1;
	color: rgba(75, 85, 99, var(--tw-text-opacity));
}

.hover\:tw-text-teal-900:hover {
	--tw-text-opacity: 1;
	color: rgba(1, 68, 81, var(--tw-text-opacity));
}

.hover\:tw-text-gray-800:hover {
	--tw-text-opacity: 1;
	color: rgba(31, 41, 55, var(--tw-text-opacity));
}

.hover\:tw-text-teal-600:hover {
	--tw-text-opacity: 1;
	color: rgba(4, 116, 129, var(--tw-text-opacity));
}

.hover\:tw-text-gray-700:hover {
	--tw-text-opacity: 1;
	color: rgba(55, 65, 81, var(--tw-text-opacity));
}

.hover\:tw-text-teal-700:hover {
	--tw-text-opacity: 1;
	color: rgba(3, 102, 114, var(--tw-text-opacity));
}

.hover\:tw-text-red-600:hover {
	--tw-text-opacity: 1;
	color: rgba(220, 38, 38, var(--tw-text-opacity));
}

.hover\:tw-text-gray-900:hover {
	--tw-text-opacity: 1;
	color: rgba(17, 24, 39, var(--tw-text-opacity));
}

.hover\:tw-text-black:hover {
	--tw-text-opacity: 1;
	color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.hover\:tw-text-orange-600:hover {
	--tw-text-opacity: 1;
	color: rgba(208, 56, 1, var(--tw-text-opacity));
}

.hover\:tw-text-gray-100:hover {
	--tw-text-opacity: 1;
	color: rgba(243, 244, 246, var(--tw-text-opacity));
}

.hover\:tw-text-teal-300:hover {
	--tw-text-opacity: 1;
	color: rgba(126, 220, 226, var(--tw-text-opacity));
}

.hover\:tw-text-gray-500:hover {
	--tw-text-opacity: 1;
	color: rgba(107, 114, 128, var(--tw-text-opacity));
}

.hover\:tw-text-red-900:hover {
	--tw-text-opacity: 1;
	color: rgba(127, 29, 29, var(--tw-text-opacity));
}

.hover\:tw-text-red-700:hover {
	--tw-text-opacity: 1;
	color: rgba(185, 28, 28, var(--tw-text-opacity));
}

.hover\:tw-text-gray-400:hover {
	--tw-text-opacity: 1;
	color: rgba(156, 163, 175, var(--tw-text-opacity));
}

.hover\:tw-text-zinc-900:hover {
	--tw-text-opacity: 1;
	color: rgba(24, 24, 27, var(--tw-text-opacity));
}

.hover\:tw-text-gray-300:hover {
	--tw-text-opacity: 1;
	color: rgba(209, 213, 219, var(--tw-text-opacity));
}

.hover\:tw-text-green-600:hover {
	--tw-text-opacity: 1;
	color: rgba(5, 150, 105, var(--tw-text-opacity));
}

.hover\:tw-text-zinc-800:hover {
	--tw-text-opacity: 1;
	color: rgba(39, 39, 42, var(--tw-text-opacity));
}

.hover\:tw-text-green-400:hover {
	--tw-text-opacity: 1;
	color: rgba(52, 211, 153, var(--tw-text-opacity));
}

.hover\:tw-text-indigo-500:hover {
	--tw-text-opacity: 1;
	color: rgba(99, 102, 241, var(--tw-text-opacity));
}

.hover\:tw-text-zinc-100:hover {
	--tw-text-opacity: 1;
	color: rgba(244, 244, 245, var(--tw-text-opacity));
}

.hover\:tw-text-zinc-50:hover {
	--tw-text-opacity: 1;
	color: rgba(250, 250, 250, var(--tw-text-opacity));
}

.hover\:tw-text-green-700:hover {
	--tw-text-opacity: 1;
	color: rgba(4, 120, 87, var(--tw-text-opacity));
}

.hover\:tw-text-green-500:hover {
	--tw-text-opacity: 1;
	color: rgba(16, 185, 129, var(--tw-text-opacity));
}

.hover\:tw-no-underline:hover {
	text-decoration: none;
}

.hover\:tw-opacity-100:hover {
	opacity: 1;
}

.hover\:tw-shadow-lg:hover {
	--tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
	box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
	box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:tw-z-10:focus {
	z-index: 10;
}

.focus\:\!tw-border-solid:focus {
	border-style: solid !important;
}

.focus\:tw-border-orange-700:focus {
	--tw-border-opacity: 1;
	border-color: rgba(180, 52, 3, var(--tw-border-opacity));
}

.focus\:tw-border-teal-700:focus {
	--tw-border-opacity: 1;
	border-color: rgba(3, 102, 114, var(--tw-border-opacity));
}

.focus\:tw-border-blue-300:focus {
	--tw-border-opacity: 1;
	border-color: rgba(147, 197, 253, var(--tw-border-opacity));
}

.focus\:tw-border-teal-500:focus {
	--tw-border-opacity: 1;
	border-color: rgba(6, 148, 162, var(--tw-border-opacity));
}

.focus\:tw-border-red-700:focus {
	--tw-border-opacity: 1;
	border-color: rgba(185, 28, 28, var(--tw-border-opacity));
}

.focus\:tw-border-indigo-700:focus {
	--tw-border-opacity: 1;
	border-color: rgba(67, 56, 202, var(--tw-border-opacity));
}

.focus\:tw-border-gray-700:focus {
	--tw-border-opacity: 1;
	border-color: rgba(55, 65, 81, var(--tw-border-opacity));
}

.focus\:tw-border-teal-300:focus {
	--tw-border-opacity: 1;
	border-color: rgba(126, 220, 226, var(--tw-border-opacity));
}

.focus\:\!tw-border-teal-500:focus {
	--tw-border-opacity: 1 !important;
	border-color: rgba(6, 148, 162, var(--tw-border-opacity)) !important;
}

.focus\:tw-bg-orange-900:focus {
	--tw-bg-opacity: 1;
	background-color: rgba(115, 35, 13, var(--tw-bg-opacity));
}

.focus\:tw-bg-gray-400:focus {
	--tw-bg-opacity: 1;
	background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
}

.focus\:tw-bg-gray-700:focus {
	--tw-bg-opacity: 1;
	background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
}

.focus\:tw-bg-white:focus {
	--tw-bg-opacity: 1;
	background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.focus\:tw-text-white:focus {
	--tw-text-opacity: 1;
	color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.focus\:tw-text-red-900:focus {
	--tw-text-opacity: 1;
	color: rgba(127, 29, 29, var(--tw-text-opacity));
}

.focus\:tw-text-zinc-800:focus {
	--tw-text-opacity: 1;
	color: rgba(39, 39, 42, var(--tw-text-opacity));
}

.focus\:tw-underline:focus {
	text-decoration: underline;
}

.focus\:tw-no-underline:focus {
	text-decoration: none;
}

.focus\:tw-placeholder-transparent:focus::-moz-placeholder {
	color: transparent;
}

.focus\:tw-placeholder-transparent:focus::placeholder {
	color: transparent;
}

.focus\:tw-placeholder-gray-500:focus::-moz-placeholder {
	--tw-placeholder-opacity: 1;
	color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
}

.focus\:tw-placeholder-gray-500:focus::placeholder {
	--tw-placeholder-opacity: 1;
	color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
}

.focus\:tw-placeholder-gray-300:focus::-moz-placeholder {
	--tw-placeholder-opacity: 1;
	color: rgba(209, 213, 219, var(--tw-placeholder-opacity));
}

.focus\:tw-placeholder-gray-300:focus::placeholder {
	--tw-placeholder-opacity: 1;
	color: rgba(209, 213, 219, var(--tw-placeholder-opacity));
}

.focus\:tw-outline-none:focus {
	outline: 2px solid transparent;
	outline-offset: 2px;
}

.focus\:\!tw-outline-none:focus {
	outline: 2px solid transparent !important;
	outline-offset: 2px !important;
}

.focus\:tw-ring-2:focus {
	--tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
	--tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
	box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), 0 0 #0000;
	box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:tw-ring-1:focus {
	--tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
	--tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
	box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), 0 0 #0000;
	box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:\!tw-ring-0:focus {
	--tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
	--tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
	box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), 0 0 #0000 !important;
	box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
}

.focus\:tw-ring:focus {
	--tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
	--tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
	box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), 0 0 #0000;
	box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:tw-ring-inset:focus {
	--tw-ring-inset: inset;
}

.focus\:tw-ring-teal-500:focus {
	--tw-ring-opacity: 1;
	--tw-ring-color: rgba(6, 148, 162, var(--tw-ring-opacity));
}

.focus\:tw-ring-indigo-500:focus {
	--tw-ring-opacity: 1;
	--tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
}

.focus\:tw-ring-teal-600:focus {
	--tw-ring-opacity: 1;
	--tw-ring-color: rgba(4, 116, 129, var(--tw-ring-opacity));
}

.focus\:tw-ring-red-500:focus {
	--tw-ring-opacity: 1;
	--tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity));
}

.focus\:tw-ring-gray-300:focus {
	--tw-ring-opacity: 1;
	--tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity));
}

.focus\:tw-ring-teal-300:focus {
	--tw-ring-opacity: 1;
	--tw-ring-color: rgba(126, 220, 226, var(--tw-ring-opacity));
}

.focus\:tw-ring-offset-2:focus {
	--tw-ring-offset-width: 2px;
}

.focus\:\!tw-ring-offset-0:focus {
	--tw-ring-offset-width: 0px !important;
}

.active\:tw-bg-orange-700:active {
	--tw-bg-opacity: 1;
	background-color: rgba(180, 52, 3, var(--tw-bg-opacity));
}

.active\:tw-bg-teal-700:active {
	--tw-bg-opacity: 1;
	background-color: rgba(3, 102, 114, var(--tw-bg-opacity));
}

.active\:tw-bg-red-700:active {
	--tw-bg-opacity: 1;
	background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
}

.active\:tw-bg-indigo-700:active {
	--tw-bg-opacity: 1;
	background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
}

.active\:tw-bg-gray-700:active {
	--tw-bg-opacity: 1;
	background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
}

.active\:tw-bg-teal-500:active {
	--tw-bg-opacity: 1;
	background-color: rgba(6, 148, 162, var(--tw-bg-opacity));
}

.active\:tw-bg-teal-200:active {
	--tw-bg-opacity: 1;
	background-color: rgba(175, 236, 239, var(--tw-bg-opacity));
}

.active\:tw-no-underline:active {
	text-decoration: none;
}

.tw-group:hover .group-hover\:tw-visible {
	visibility: visible;
}

.tw-group:hover .group-hover\:tw-block {
	display: block;
}

.tw-group:hover .group-hover\:tw-flex {
	display: flex;
}

.tw-group:hover .group-hover\:tw-inline-flex {
	display: inline-flex;
}

.tw-group:hover .group-hover\:tw-hidden {
	display: none;
}

.tw-group:hover .group-hover\:tw-bg-gray-100 {
	--tw-bg-opacity: 1;
	background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}

.tw-group:hover .group-hover\:tw-bg-gray-200 {
	--tw-bg-opacity: 1;
	background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}

.tw-group:hover .group-hover\:tw-bg-teal-50 {
	--tw-bg-opacity: 1;
	background-color: rgba(237, 250, 250, var(--tw-bg-opacity));
}

.tw-group:hover .group-hover\:tw-bg-gray-50 {
	--tw-bg-opacity: 1;
	background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}

.tw-group:hover .group-hover\:tw-text-gray-700 {
	--tw-text-opacity: 1;
	color: rgba(55, 65, 81, var(--tw-text-opacity));
}

.tw-group:hover .group-hover\:tw-text-teal-600 {
	--tw-text-opacity: 1;
	color: rgba(4, 116, 129, var(--tw-text-opacity));
}

.tw-group:hover .group-hover\:tw-text-zinc-600 {
	--tw-text-opacity: 1;
	color: rgba(82, 82, 91, var(--tw-text-opacity));
}

.tw-group:hover .group-hover\:tw-text-zinc-900 {
	--tw-text-opacity: 1;
	color: rgba(24, 24, 27, var(--tw-text-opacity));
}

.tw-group:hover .group-hover\:tw-text-gray-800 {
	--tw-text-opacity: 1;
	color: rgba(31, 41, 55, var(--tw-text-opacity));
}

.tw-group:hover .group-hover\:tw-text-gray-600 {
	--tw-text-opacity: 1;
	color: rgba(75, 85, 99, var(--tw-text-opacity));
}

.tw-group:hover .group-hover\:tw-text-teal-800 {
	--tw-text-opacity: 1;
	color: rgba(5, 80, 92, var(--tw-text-opacity));
}

.tw-group:hover .group-hover\:tw-text-red-700 {
	--tw-text-opacity: 1;
	color: rgba(185, 28, 28, var(--tw-text-opacity));
}

.tw-group:hover .group-hover\:tw-text-teal-500 {
	--tw-text-opacity: 1;
	color: rgba(6, 148, 162, var(--tw-text-opacity));
}

.tw-group:hover .group-hover\:tw-text-gray-500 {
	--tw-text-opacity: 1;
	color: rgba(107, 114, 128, var(--tw-text-opacity));
}

.tw-group:hover .group-hover\:tw-text-teal-700 {
	--tw-text-opacity: 1;
	color: rgba(3, 102, 114, var(--tw-text-opacity));
}

.tw-group:hover .group-hover\:tw-text-green-700 {
	--tw-text-opacity: 1;
	color: rgba(4, 120, 87, var(--tw-text-opacity));
}

.tw-group:hover .group-hover\:tw-text-zinc-800 {
	--tw-text-opacity: 1;
	color: rgba(39, 39, 42, var(--tw-text-opacity));
}

.tw-group:hover .group-hover\:tw-text-gray-900 {
	--tw-text-opacity: 1;
	color: rgba(17, 24, 39, var(--tw-text-opacity));
}

.tw-group:active .group-active\:tw-flex {
	display: flex;
}

@media (min-width: 640px) {

	.sm\:tw-col-span-7 {
		grid-column: span 7 / span 7;
	}

	.sm\:tw-col-span-5 {
		grid-column: span 5 / span 5;
	}

	.sm\:tw-col-span-6 {
		grid-column: span 6 / span 6;
	}

	.sm\:tw-col-span-3 {
		grid-column: span 3 / span 3;
	}

	.sm\:tw-col-span-4 {
		grid-column: span 4 / span 4;
	}

	.sm\:tw-col-span-8 {
		grid-column: span 8 / span 8;
	}

	.sm\:tw-col-span-2 {
		grid-column: span 2 / span 2;
	}

	.sm\:tw-col-span-12 {
		grid-column: span 12 / span 12;
	}

	.sm\:tw-m-0 {
		margin: 0px;
	}

	.sm\:tw-mx-2 {
		margin-left: 0.5rem;
		margin-right: 0.5rem;
	}

	.sm\:tw-my-8 {
		margin-top: 2rem;
		margin-bottom: 2rem;
	}

	.sm\:tw-mx-auto {
		margin-left: auto;
		margin-right: auto;
	}

	.sm\:tw-mt-0 {
		margin-top: 0px;
	}

	.sm\:tw-mt-14 {
		margin-top: 3.5rem;
	}

	.sm\:tw-mt-10 {
		margin-top: 2.5rem;
	}

	.sm\:tw-mt-4 {
		margin-top: 1rem;
	}

	.sm\:tw-mt-1 {
		margin-top: 0.25rem;
	}

	.sm\:tw-ml-3 {
		margin-left: 0.75rem;
	}

	.sm\:tw-ml-6 {
		margin-left: 1.5rem;
	}

	.sm\:tw-block {
		display: block;
	}

	.sm\:tw-inline-block {
		display: inline-block;
	}

	.sm\:tw-flex {
		display: flex;
	}

	.sm\:tw-hidden {
		display: none;
	}

	.sm\:tw-h-\[363px\] {
		height: 363px;
	}

	.sm\:tw-h-\[300px\] {
		height: 300px;
	}

	.sm\:tw-h-72 {
		height: 18rem;
	}

	.sm\:tw-h-48 {
		height: 12rem;
	}

	.sm\:tw-h-6 {
		height: 1.5rem;
	}

	.sm\:tw-h-screen {
		height: 100vh;
	}

	.sm\:tw-h-12 {
		height: 3rem;
	}

	.sm\:tw-w-\[600px\] {
		width: 600px;
	}

	.sm\:tw-w-40 {
		width: 10rem;
	}

	.sm\:tw-w-48 {
		width: 12rem;
	}

	.sm\:tw-w-1\/4 {
		width: 25%;
	}

	.sm\:tw-w-full {
		width: 100%;
	}

	.sm\:tw-w-auto {
		width: auto;
	}

	.sm\:tw-w-1\/2 {
		width: 50%;
	}

	.sm\:tw-w-2\/3 {
		width: 66.666667%;
	}

	.sm\:tw-w-1\/3 {
		width: 33.333333%;
	}

	.sm\:tw-w-32 {
		width: 8rem;
	}

	.sm\:tw-w-\[500px\] {
		width: 500px;
	}

	.sm\:tw-w-\[700px\] {
		width: 700px;
	}

	.sm\:tw-w-max {
		width: -webkit-max-content;
		width: -moz-max-content;
		width: max-content;
	}

	.sm\:tw-w-12 {
		width: 3rem;
	}

	.sm\:tw-max-w-5xl {
		max-width: 64rem;
	}

	.sm\:tw-max-w-6xl {
		max-width: 72rem;
	}

	.sm\:tw-max-w-md {
		max-width: 28rem;
	}

	.sm\:tw-max-w-lg {
		max-width: 32rem;
	}

	.sm\:tw-flex-shrink-0 {
		flex-shrink: 0;
	}

	.sm\:tw-grid-cols-2 {
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}

	.sm\:tw-grid-cols-3 {
		grid-template-columns: repeat(3, minmax(0, 1fr));
	}

	.sm\:tw-grid-cols-6 {
		grid-template-columns: repeat(6, minmax(0, 1fr));
	}

	.sm\:tw-flex-row {
		flex-direction: row;
	}

	.sm\:tw-items-start {
		align-items: flex-start;
	}

	.sm\:tw-items-end {
		align-items: flex-end;
	}

	.sm\:tw-items-center {
		align-items: center;
	}

	.sm\:tw-justify-end {
		justify-content: flex-end;
	}

	.sm\:tw-justify-between {
		justify-content: space-between;
	}

	.sm\:tw-gap-2 {
		grid-gap: 0.5rem;
		gap: 0.5rem;
	}

	.sm\:tw-gap-6 {
		grid-gap: 1.5rem;
		gap: 1.5rem;
	}

	.sm\:tw-gap-x-8 {
		grid-column-gap: 2rem;
		-moz-column-gap: 2rem;
		     column-gap: 2rem;
	}

	.sm\:tw-gap-x-20 {
		grid-column-gap: 5rem;
		-moz-column-gap: 5rem;
		     column-gap: 5rem;
	}

	.sm\:tw-gap-x-6 {
		grid-column-gap: 1.5rem;
		-moz-column-gap: 1.5rem;
		     column-gap: 1.5rem;
	}

	.sm\:tw-space-y-0 > :not([hidden]) ~ :not([hidden]) {
		--tw-space-y-reverse: 0;
		margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
		margin-bottom: calc(0px * var(--tw-space-y-reverse));
	}

	.sm\:tw-space-x-10 > :not([hidden]) ~ :not([hidden]) {
		--tw-space-x-reverse: 0;
		margin-right: calc(2.5rem * var(--tw-space-x-reverse));
		margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
	}

	.sm\:tw-space-x-4 > :not([hidden]) ~ :not([hidden]) {
		--tw-space-x-reverse: 0;
		margin-right: calc(1rem * var(--tw-space-x-reverse));
		margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
	}

	.sm\:tw-space-x-6 > :not([hidden]) ~ :not([hidden]) {
		--tw-space-x-reverse: 0;
		margin-right: calc(1.5rem * var(--tw-space-x-reverse));
		margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
	}

	.sm\:tw-space-x-2 > :not([hidden]) ~ :not([hidden]) {
		--tw-space-x-reverse: 0;
		margin-right: calc(0.5rem * var(--tw-space-x-reverse));
		margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
	}

	.sm\:tw-space-x-0 > :not([hidden]) ~ :not([hidden]) {
		--tw-space-x-reverse: 0;
		margin-right: calc(0px * var(--tw-space-x-reverse));
		margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
	}

	.sm\:tw-overflow-hidden {
		overflow: hidden;
	}

	.sm\:tw-truncate {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.sm\:tw-rounded-md {
		border-radius: 0.375rem;
	}

	.sm\:tw-rounded-lg {
		border-radius: 0.5rem;
	}

	.sm\:tw-border-2 {
		border-width: 2px;
	}

	.sm\:tw-border-black {
		--tw-border-opacity: 1;
		border-color: rgba(0, 0, 0, var(--tw-border-opacity));
	}

	.sm\:tw-p-6 {
		padding: 1.5rem;
	}

	.sm\:tw-p-8 {
		padding: 2rem;
	}

	.sm\:tw-p-0 {
		padding: 0px;
	}

	.sm\:tw-px-6 {
		padding-left: 1.5rem;
		padding-right: 1.5rem;
	}

	.sm\:tw-px-0 {
		padding-left: 0px;
		padding-right: 0px;
	}

	.sm\:tw-px-3 {
		padding-left: 0.75rem;
		padding-right: 0.75rem;
	}

	.sm\:tw-py-4 {
		padding-top: 1rem;
		padding-bottom: 1rem;
	}

	.sm\:tw-px-5 {
		padding-left: 1.25rem;
		padding-right: 1.25rem;
	}

	.sm\:tw-py-2 {
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
	}

	.sm\:tw-px-12 {
		padding-left: 3rem;
		padding-right: 3rem;
	}

	.sm\:tw-px-4 {
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.sm\:tw-px-8 {
		padding-left: 2rem;
		padding-right: 2rem;
	}

	.sm\:tw-py-8 {
		padding-top: 2rem;
		padding-bottom: 2rem;
	}

	.sm\:tw-px-10 {
		padding-left: 2.5rem;
		padding-right: 2.5rem;
	}

	.sm\:tw-px-2 {
		padding-left: 0.5rem;
		padding-right: 0.5rem;
	}

	.sm\:tw-pt-6 {
		padding-top: 1.5rem;
	}

	.sm\:tw-pt-14 {
		padding-top: 3.5rem;
	}

	.sm\:tw-pr-8 {
		padding-right: 2rem;
	}

	.sm\:tw-pr-16 {
		padding-right: 4rem;
	}

	.sm\:tw-pl-8 {
		padding-left: 2rem;
	}

	.sm\:tw-pr-4 {
		padding-right: 1rem;
	}

	.sm\:tw-pb-5 {
		padding-bottom: 1.25rem;
	}

	.sm\:tw-pl-6 {
		padding-left: 1.5rem;
	}

	.sm\:tw-pr-24 {
		padding-right: 6rem;
	}

	.sm\:tw-text-left {
		text-align: left;
	}

	.sm\:tw-align-middle {
		vertical-align: middle;
	}

	.sm\:tw-text-sm {
		font-size: 0.875rem;
		line-height: 1.25rem;
	}

	.sm\:tw-text-3xl {
		font-size: 1.875rem;
		line-height: 2.25rem;
	}

	.sm\:tw-text-2xl {
		font-size: 1.5rem;
		line-height: 2rem;
	}

	.sm\:tw-text-5xl {
		font-size: 3rem;
		line-height: 1;
	}

	.sm\:tw-text-lg {
		font-size: 1.125rem;
		line-height: 1.75rem;
	}

	.sm\:tw-text-xs {
		font-size: 0.75rem;
		line-height: 1rem;
	}

	.sm\:tw-text-4xl {
		font-size: 2.25rem;
		line-height: 2.5rem;
	}

	.sm\:tw-leading-5 {
		line-height: 1.25rem;
	}

	.sm\:tw-leading-6 {
		line-height: 1.5rem;
	}

	.sm\:tw-leading-9 {
		line-height: 2.25rem;
	}

	.sm\:tw-tracking-normal {
		letter-spacing: 0em;
	}

	.sm\:tw-tracking-tight {
		letter-spacing: -0.025em;
	}
}

@media (min-width: 768px) {

	.md\:tw-col-span-2 {
		grid-column: span 2 / span 2;
	}

	.md\:tw-col-span-3 {
		grid-column: span 3 / span 3;
	}

	.md\:tw-col-span-9 {
		grid-column: span 9 / span 9;
	}

	.md\:tw-mx-auto {
		margin-left: auto;
		margin-right: auto;
	}

	.md\:tw-ml-6 {
		margin-left: 1.5rem;
	}

	.md\:tw-mt-2 {
		margin-top: 0.5rem;
	}

	.md\:tw-mt-0 {
		margin-top: 0px;
	}

	.md\:tw-block {
		display: block;
	}

	.md\:tw-inline-block {
		display: inline-block;
	}

	.md\:tw-hidden {
		display: none;
	}

	.md\:tw-h-36 {
		height: 9rem;
	}

	.md\:tw-h-24 {
		height: 6rem;
	}

	.md\:tw-h-8 {
		height: 2rem;
	}

	.md\:tw-h-16 {
		height: 4rem;
	}

	.md\:tw-h-48 {
		height: 12rem;
	}

	.md\:tw-w-36 {
		width: 9rem;
	}

	.md\:tw-w-24 {
		width: 6rem;
	}

	.md\:tw-w-1\/5 {
		width: 20%;
	}

	.md\:tw-w-4\/5 {
		width: 80%;
	}

	.md\:tw-w-1\/3 {
		width: 33.333333%;
	}

	.md\:tw-w-2\/3 {
		width: 66.666667%;
	}

	.md\:tw-w-48 {
		width: 12rem;
	}

	.md\:tw-w-1\/12 {
		width: 8.333333%;
	}

	.md\:tw-w-16 {
		width: 4rem;
	}

	.md\:tw-w-32 {
		width: 8rem;
	}

	.md\:tw-grid-cols-3 {
		grid-template-columns: repeat(3, minmax(0, 1fr));
	}

	.md\:tw-justify-start {
		justify-content: flex-start;
	}

	.md\:tw-gap-6 {
		grid-gap: 1.5rem;
		gap: 1.5rem;
	}

	.md\:tw-gap-8 {
		grid-gap: 2rem;
		gap: 2rem;
	}

	.md\:tw-gap-10 {
		grid-gap: 2.5rem;
		gap: 2.5rem;
	}

	.md\:tw-gap-2 {
		grid-gap: 0.5rem;
		gap: 0.5rem;
	}

	.md\:tw-gap-x-1 {
		grid-column-gap: 0.25rem;
		-moz-column-gap: 0.25rem;
		     column-gap: 0.25rem;
	}

	.md\:tw-space-y-3 > :not([hidden]) ~ :not([hidden]) {
		--tw-space-y-reverse: 0;
		margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
		margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
	}

	.md\:tw-space-x-10 > :not([hidden]) ~ :not([hidden]) {
		--tw-space-x-reverse: 0;
		margin-right: calc(2.5rem * var(--tw-space-x-reverse));
		margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
	}

	.md\:tw-bg-right-top {
		background-position: right top;
	}

	.md\:tw-px-4 {
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.md\:tw-py-1\.5 {
		padding-top: 0.375rem;
		padding-bottom: 0.375rem;
	}

	.md\:tw-py-1 {
		padding-top: 0.25rem;
		padding-bottom: 0.25rem;
	}

	.md\:tw-pr-0 {
		padding-right: 0px;
	}

	.md\:tw-pl-3 {
		padding-left: 0.75rem;
	}

	.md\:tw-pr-3 {
		padding-right: 0.75rem;
	}

	.md\:tw-text-left {
		text-align: left;
	}

	.md\:tw-text-2xl {
		font-size: 1.5rem;
		line-height: 2rem;
	}
}

@media (min-width: 1024px) {

	.lg\:tw-col-span-4 {
		grid-column: span 4 / span 4;
	}

	.lg\:tw-col-span-8 {
		grid-column: span 8 / span 8;
	}

	.lg\:tw-col-span-9 {
		grid-column: span 9 / span 9;
	}

	.lg\:tw-col-span-6 {
		grid-column: span 6 / span 6;
	}

	.lg\:tw-col-span-3 {
		grid-column: span 3 / span 3;
	}

	.lg\:tw-col-span-2 {
		grid-column: span 2 / span 2;
	}

	.lg\:tw-col-span-12 {
		grid-column: span 12 / span 12;
	}

	.lg\:tw--ml-2 {
		margin-left: -0.5rem;
	}

	.lg\:tw-mt-0 {
		margin-top: 0px;
	}

	.lg\:tw-block {
		display: block;
	}

	.lg\:tw-flex {
		display: flex;
	}

	.lg\:tw-table-cell {
		display: table-cell;
	}

	.lg\:tw-grid {
		display: grid;
	}

	.lg\:tw-h-72 {
		height: 18rem;
	}

	.lg\:tw-h-5 {
		height: 1.25rem;
	}

	.lg\:tw-w-96 {
		width: 24rem;
	}

	.lg\:tw-w-full {
		width: 100%;
	}

	.lg\:tw-w-72 {
		width: 18rem;
	}

	.lg\:tw-w-1\/3 {
		width: 33.333333%;
	}

	.lg\:tw-w-20 {
		width: 5rem;
	}

	.lg\:tw-w-5 {
		width: 1.25rem;
	}

	.lg\:tw-max-w-5xl {
		max-width: 64rem;
	}

	.lg\:tw-max-w-md {
		max-width: 28rem;
	}

	.lg\:tw-max-w-xs {
		max-width: 20rem;
	}

	.lg\:tw-max-w-xl {
		max-width: 36rem;
	}

	.lg\:tw-max-w-lg {
		max-width: 32rem;
	}

	.lg\:tw-flex-none {
		flex: none;
	}

	.lg\:tw-grid-cols-12 {
		grid-template-columns: repeat(12, minmax(0, 1fr));
	}

	.lg\:tw-grid-cols-4 {
		grid-template-columns: repeat(4, minmax(0, 1fr));
	}

	.lg\:tw-grid-cols-3 {
		grid-template-columns: repeat(3, minmax(0, 1fr));
	}

	.lg\:tw-flex-wrap {
		flex-wrap: wrap;
	}

	.lg\:tw-justify-end {
		justify-content: flex-end;
	}

	.lg\:tw-gap-20 {
		grid-gap: 5rem;
		gap: 5rem;
	}

	.lg\:tw-gap-16 {
		grid-gap: 4rem;
		gap: 4rem;
	}

	.lg\:tw-gap-8 {
		grid-gap: 2rem;
		gap: 2rem;
	}

	.lg\:tw-gap-4 {
		grid-gap: 1rem;
		gap: 1rem;
	}

	.lg\:tw-gap-x-5 {
		grid-column-gap: 1.25rem;
		-moz-column-gap: 1.25rem;
		     column-gap: 1.25rem;
	}

	.lg\:tw-space-x-2 > :not([hidden]) ~ :not([hidden]) {
		--tw-space-x-reverse: 0;
		margin-right: calc(0.5rem * var(--tw-space-x-reverse));
		margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
	}

	.lg\:tw-space-y-0 > :not([hidden]) ~ :not([hidden]) {
		--tw-space-y-reverse: 0;
		margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
		margin-bottom: calc(0px * var(--tw-space-y-reverse));
	}

	.lg\:tw-border-none {
		border-style: none;
	}

	.lg\:tw-px-8 {
		padding-left: 2rem;
		padding-right: 2rem;
	}

	.lg\:tw-py-20 {
		padding-top: 5rem;
		padding-bottom: 5rem;
	}

	.lg\:tw-px-12 {
		padding-left: 3rem;
		padding-right: 3rem;
	}

	.lg\:tw-py-4 {
		padding-top: 1rem;
		padding-bottom: 1rem;
	}

	.lg\:tw-px-4 {
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.lg\:tw-py-6 {
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;
	}

	.lg\:tw-px-2 {
		padding-left: 0.5rem;
		padding-right: 0.5rem;
	}

	.lg\:tw-px-10 {
		padding-left: 2.5rem;
		padding-right: 2.5rem;
	}

	.lg\:tw-px-0 {
		padding-left: 0px;
		padding-right: 0px;
	}

	.lg\:tw-py-0 {
		padding-top: 0px;
		padding-bottom: 0px;
	}

	.lg\:tw-px-24 {
		padding-left: 6rem;
		padding-right: 6rem;
	}

	.lg\:tw-px-20 {
		padding-left: 5rem;
		padding-right: 5rem;
	}

	.lg\:tw-pl-16 {
		padding-left: 4rem;
	}

	.lg\:tw-text-3xl {
		font-size: 1.875rem;
		line-height: 2.25rem;
	}

	.lg\:tw-text-5xl {
		font-size: 3rem;
		line-height: 1;
	}

	.lg\:tw-text-sm {
		font-size: 0.875rem;
		line-height: 1.25rem;
	}

	.lg\:tw-text-4xl {
		font-size: 2.25rem;
		line-height: 2.5rem;
	}

	.lg\:tw-tracking-tight {
		letter-spacing: -0.025em;
	}
}

@media (min-width: 1280px) {

	.xl\:tw-px-32 {
		padding-left: 8rem;
		padding-right: 8rem;
	}

	.xl\:tw-px-24 {
		padding-left: 6rem;
		padding-right: 6rem;
	}
}

@media print {

	.print\:tw-prose-sm {
		font-size: 0.875rem;
		line-height: 1.7142857;
	}

	.print\:tw-prose-sm p {
		margin-top: 1.1428571em;
		margin-bottom: 1.1428571em;
	}

	.print\:tw-prose-sm [class~="lead"] {
		font-size: 1.2857143em;
		line-height: 1.5555556;
		margin-top: 0.8888889em;
		margin-bottom: 0.8888889em;
	}

	.print\:tw-prose-sm blockquote {
		margin-top: 1.3333333em;
		margin-bottom: 1.3333333em;
		padding-left: 1.1111111em;
	}

	.print\:tw-prose-sm h1 {
		font-size: 2.1428571em;
		margin-top: 0;
		margin-bottom: 0.8em;
		line-height: 1.2;
	}

	.print\:tw-prose-sm h2 {
		font-size: 1.4285714em;
		margin-top: 1.6em;
		margin-bottom: 0.8em;
		line-height: 1.4;
	}

	.print\:tw-prose-sm h3 {
		font-size: 1.2857143em;
		margin-top: 1.5555556em;
		margin-bottom: 0.4444444em;
		line-height: 1.5555556;
	}

	.print\:tw-prose-sm h4 {
		margin-top: 1.4285714em;
		margin-bottom: 0.5714286em;
		line-height: 1.4285714;
	}

	.print\:tw-prose-sm img {
		margin-top: 1.7142857em;
		margin-bottom: 1.7142857em;
	}

	.print\:tw-prose-sm video {
		margin-top: 1.7142857em;
		margin-bottom: 1.7142857em;
	}

	.print\:tw-prose-sm figure {
		margin-top: 1.7142857em;
		margin-bottom: 1.7142857em;
	}

	.print\:tw-prose-sm figure > * {
		margin-top: 0;
		margin-bottom: 0;
	}

	.print\:tw-prose-sm figure figcaption {
		font-size: 0.8571429em;
		line-height: 1.3333333;
		margin-top: 0.6666667em;
	}

	.print\:tw-prose-sm code {
		font-size: 0.8571429em;
	}

	.print\:tw-prose-sm h2 code {
		font-size: 0.9em;
	}

	.print\:tw-prose-sm h3 code {
		font-size: 0.8888889em;
	}

	.print\:tw-prose-sm pre {
		font-size: 0.8571429em;
		line-height: 1.6666667;
		margin-top: 1.6666667em;
		margin-bottom: 1.6666667em;
		border-radius: 0.25rem;
		padding-top: 0.6666667em;
		padding-right: 1em;
		padding-bottom: 0.6666667em;
		padding-left: 1em;
	}

	.print\:tw-prose-sm ol {
		margin-top: 1.1428571em;
		margin-bottom: 1.1428571em;
	}

	.print\:tw-prose-sm ul {
		margin-top: 1.1428571em;
		margin-bottom: 1.1428571em;
	}

	.print\:tw-prose-sm li {
		margin-top: 0.2857143em;
		margin-bottom: 0.2857143em;
	}

	.print\:tw-prose-sm ol > li {
		padding-left: 1.5714286em;
	}

	.print\:tw-prose-sm ol > li::before {
		left: 0;
	}

	.print\:tw-prose-sm ul > li {
		padding-left: 1.5714286em;
	}

	.print\:tw-prose-sm ul > li::before {
		height: 0.3571429em;
		width: 0.3571429em;
		top: calc(0.8571429em - 0.1785714em);
		left: 0.2142857em;
	}

	.print\:tw-prose-sm > ul > li p {
		margin-top: 0.5714286em;
		margin-bottom: 0.5714286em;
	}

	.print\:tw-prose-sm > ul > li > *:first-child {
		margin-top: 1.1428571em;
	}

	.print\:tw-prose-sm > ul > li > *:last-child {
		margin-bottom: 1.1428571em;
	}

	.print\:tw-prose-sm > ol > li > *:first-child {
		margin-top: 1.1428571em;
	}

	.print\:tw-prose-sm > ol > li > *:last-child {
		margin-bottom: 1.1428571em;
	}

	.print\:tw-prose-sm ul ul, .print\:tw-prose-sm ul ol, .print\:tw-prose-sm ol ul, .print\:tw-prose-sm ol ol {
		margin-top: 0.5714286em;
		margin-bottom: 0.5714286em;
	}

	.print\:tw-prose-sm ul ul, .print\:tw-prose-sm ul ol, .print\:tw-prose-sm ol ul, .print\:tw-prose-sm ol ol {
		margin-top: 0.5714286em;
		margin-bottom: 0.5714286em;
	}

	.print\:tw-prose-sm ul ul, .print\:tw-prose-sm ul ol, .print\:tw-prose-sm ol ul, .print\:tw-prose-sm ol ol {
		margin-top: 0.5714286em;
		margin-bottom: 0.5714286em;
	}

	.print\:tw-prose-sm ul ul, .print\:tw-prose-sm ul ol, .print\:tw-prose-sm ol ul, .print\:tw-prose-sm ol ol {
		margin-top: 0.5714286em;
		margin-bottom: 0.5714286em;
	}

	.print\:tw-prose-sm hr {
		margin-top: 2.8571429em;
		margin-bottom: 2.8571429em;
	}

	.print\:tw-prose-sm hr + * {
		margin-top: 0;
	}

	.print\:tw-prose-sm h2 + * {
		margin-top: 0;
	}

	.print\:tw-prose-sm h3 + * {
		margin-top: 0;
	}

	.print\:tw-prose-sm h4 + * {
		margin-top: 0;
	}

	.print\:tw-prose-sm table {
		font-size: 0.8571429em;
		line-height: 1.5;
	}

	.print\:tw-prose-sm thead th {
		padding-right: 1em;
		padding-bottom: 0.6666667em;
		padding-left: 1em;
	}

	.print\:tw-prose-sm thead th:first-child {
		padding-left: 0;
	}

	.print\:tw-prose-sm thead th:last-child {
		padding-right: 0;
	}

	.print\:tw-prose-sm tbody td {
		padding-top: 0.6666667em;
		padding-right: 1em;
		padding-bottom: 0.6666667em;
		padding-left: 1em;
	}

	.print\:tw-prose-sm tbody td:first-child {
		padding-left: 0;
	}

	.print\:tw-prose-sm tbody td:last-child {
		padding-right: 0;
	}

	.print\:tw-prose-sm > :first-child {
		margin-top: 0;
	}

	.print\:tw-prose-sm > :last-child {
		margin-bottom: 0;
	}

	.print\:tw-my-0 {
		margin-top: 0px;
		margin-bottom: 0px;
	}

	.print\:tw-mt-2 {
		margin-top: 0.5rem;
	}

	.print\:tw-mr-0 {
		margin-right: 0px;
	}

	.print\:tw-inline {
		display: inline;
	}

	.print\:tw-hidden {
		display: none;
	}

	.print\:tw-w-1\/4 {
		width: 25%;
	}

	.print\:tw-max-w-max {
		max-width: -webkit-max-content;
		max-width: -moz-max-content;
		max-width: max-content;
	}

	.print\:tw-flex-row {
		flex-direction: row;
	}

	.print\:tw-space-y-0 > :not([hidden]) ~ :not([hidden]) {
		--tw-space-y-reverse: 0;
		margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
		margin-bottom: calc(0px * var(--tw-space-y-reverse));
	}

	.print\:tw-border-t-2 {
		border-top-width: 2px;
	}

	.print\:tw-border-b-0 {
		border-bottom-width: 0px;
	}

	.print\:tw-border-r-0 {
		border-right-width: 0px;
	}

	.print\:tw-border-l-0 {
		border-left-width: 0px;
	}

	.print\:tw-border-t {
		border-top-width: 1px;
	}

	.print\:tw-border-solid {
		border-style: solid;
	}

	.print\:tw-border-gray-500 {
		--tw-border-opacity: 1;
		border-color: rgba(107, 114, 128, var(--tw-border-opacity));
	}

	.print\:tw-border-gray-100 {
		--tw-border-opacity: 1;
		border-color: rgba(243, 244, 246, var(--tw-border-opacity));
	}

	.print\:tw-p-0 {
		padding: 0px;
	}

	.print\:tw-p-1 {
		padding: 0.25rem;
	}

	.print\:tw-py-0 {
		padding-top: 0px;
		padding-bottom: 0px;
	}

	.print\:tw-text-base {
		font-size: 1rem;
		line-height: 1.5rem;
	}

	.print\:tw-font-normal {
		font-weight: 400;
	}

	.print\:tw-leading-normal {
		line-height: 1.5;
	}
}