@import "tailwindcss/base";

@import "tailwindcss/components";

@import "tailwindcss/utilities";

.tw-form-input {
  @apply tw-border tw-border-solid tw-border-gray-300 tw-rounded-md focus:tw-outline-none tw-py-2 focus:tw-border-blue-300 focus:tw-ring focus:tw-ring-blue-200 focus:tw-ring-opacity-50;
}

.field_with_errors .tw-form-input {
  @apply tw-border-red-500;
}

.tw-checkbox {
  @apply focus:tw-ring-indigo-500 tw-text-teal-600 tw-border tw-border-solid tw-border-gray-300 tw-rounded;
}

.tw-radio {
  @apply tw-h-4 tw-w-4 tw-mt-0.5 tw-mb-0 tw-cursor-pointer tw-text-teal-600 tw-border tw-border-solid tw-border-gray-300 focus:tw-ring-teal-500;
}

.pagy-nav,
.pagy-nav-js {
  @apply tw-flex tw-items-center tw-pl-3 tw-space-x-1;
}

.tw-radio-label {
  @apply tw-mb-0;
}

.hidden {
  @apply tw-hidden;
}

input.tw-radio {
  @apply tw-mt-0;
}

.pagy-nav .page a,
.pagy-nav .page.active,
.pagy-nav .page.prev.disabled,
.pagy-nav .page.next.disabled,
.pagy-nav-js .page a,
.pagy-nav-js .page.active,
.pagy-nav-js .page.prev.disabled,
.pagy-nav-js .page.next.disabled {
  @apply tw-block tw-rounded-md tw-px-3 tw-py-1 tw-text-gray-500 active:tw-bg-teal-400 active:tw-text-white hover:tw-bg-gray-300 hover:tw-px-3 hover:tw-py-1 hover:tw-block;
}

.pagy-nav .page.prev.disabled,
.pagy-nav .page.next.disabled,
.pagy-nav-js .page.prev.disabled,
.pagy-nav-js .page.next.disabled {
  @apply tw-text-gray-400 tw-cursor-default hover:tw-text-gray-800 hover:tw-bg-gray-300 active:tw-bg-teal-400 active:tw-text-white;
}

.pagy-nav .page.active,
.pagy-nav-js .page.active {
  @apply tw-text-white tw-cursor-default tw-bg-teal-400 hover:tw-text-white hover:tw-bg-gray-400 active:tw-bg-teal-400 active:tw-text-white;
}

.pagination {
  @apply tw-my-0;
}

.pagy-combo-nav-js {
  @apply tw-flex tw-max-w-max tw-rounded-full tw-px-3 tw-text-gray-500 tw-font-semibold tw-bg-gray-200 tw-shadow-md;
}

.pagy-combo-nav-js .pagy-combo-input {
  @apply tw-bg-white tw-px-2 tw-rounded-sm
}

.pagy-combo-nav-js .page.prev,
.pagy-combo-nav-js .page.next {
  @apply hover:tw-text-gray-700 active:tw-text-gray-700;
}

.pagy-combo-nav-js .page.prev.disabled,
.pagy-combo-nav-js .page.next.disabled {
  @apply tw-text-gray-400 tw-cursor-default;
}

.pagy-info {
  @apply tw-text-gray-500
}

input[type="text"]:disabled {
  @apply tw-bg-gray-100 tw-text-zinc-400;
}

input[type="file"]::file-selector-button:hover {
	background-color: #f9fafb;
}

input[type="file"]::file-selector-button:active {
	background-color: #0694a2;
}

input[type="file"]::file-selector-button:focus {
	background-color: #d5f5f6;
  border: 1px solid #0694a2;
}

input[type="file"]:focus {
  @apply focus:tw-outline-none focus:tw-border-transparent
}

input[type="file"]:hover {
  color: #0694a2;
}

input[type=file]::-webkit-file-upload-button,
input[type="file"]::file-selector-button {
  @apply tw-border tw-border-solid tw-border-gray-300 tw-py-2 tw-px-4 tw-bg-white tw-rounded-md tw-mr-6 tw-font-normal tw-text-base tw-cursor-pointer;
}

/* Replicating `data-disable-with` functionality */
/* from `rails-ujs` using Turbo */
button .show-when-disabled {
  display: none;
}
button[disabled] .show-when-disabled {
  display: initial;
}

button .show-when-enabled {
  display: initial;
}
button[disabled] .show-when-enabled {
  display: none;
}